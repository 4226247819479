import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import {User as UserIcon} from '../../../icons/user';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getAllUsers} from "../../../data/user";

export function UsersCard(props: any) {
    const nav = useNavigate()
    let [usersCount, setUsersCount] = useState(0);

    useEffect(() => {
        getAllUsers().then((users: Array<any> | null) => {
            if (users) {
                setUsersCount(users.length)
            }
        })
    })

    return <Card
        sx={{height: '100%', cursor: 'pointer', boxShadow: "5px 5px 1px gray"}}
        onClick={() => nav("/home/users")}
        {...props}
        variant={"outlined"}
    >
        <CardContent>
            <Grid
                container
                spacing={3}
                sx={{justifyContent: 'space-between'}}
            >
                <Grid item>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="overline"
                    >
                        {"User" + (usersCount > 1 ? "s" : "")}
                    </Typography>
                    <Typography
                        color="textPrimary"
                        variant="h4"
                    >
                        {usersCount}
                    </Typography>
                </Grid>
                <Grid item>
                    <Avatar
                        sx={{
                            backgroundColor: 'warning.main',
                            height: 56,
                            width: 56
                        }}
                    >
                        <UserIcon/>
                    </Avatar>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}