import { Box } from "@mui/material";
import {FrameworkAssessmentForm} from "../../data/framework";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useDispatch, useSelector} from "react-redux";
import {selectMetric} from "../../store/selectors/assessment-results";
import {useParams} from "react-router-dom";
import {ChangeEvent, useState} from "react";
import {MetricResult} from "../../data/assessment-result";
import {deselectAllMetrics, selectAllMetrics, updateMetric} from "../../store/actions/assessment-results";
import Button from "@mui/material/Button";

export interface FrameworkTreeProps {
    form: FrameworkAssessmentForm
}

export function FrameworkTree({form} : FrameworkTreeProps) {
    const dispatch = useDispatch()
    let {id} = useParams()
    let assessment_id = id ? id : ""

    let [treeUpdates, setTreeUpdate] = useState(0)

    const handleSelectAllMetrics = () => {
        dispatch(selectAllMetrics(assessment_id, form))
        setTreeUpdate(treeUpdates + 1)
    }

    const handleDeselectAllMetrics = () => {
        dispatch(deselectAllMetrics(assessment_id, form))
        setTreeUpdate(treeUpdates + 1)
    }

    return <Box>
        <h3>Framework</h3>
        <p>Here, you can select which metrics to use in the assessment (only selected metrics will be used).</p>
        <Box sx={{display: 'flex', flexDirection: 'row', marginBottom: '1em'}}>
            <Box sx={{marginRight: '2em'}}><Button variant={"outlined"} onClick={handleSelectAllMetrics}>Select all metrics</Button></Box>
            <Box><Button variant={"outlined"} onClick={handleDeselectAllMetrics}>Deselect all metrics</Button></Box>
        </Box>
        <hr/>
        <Box>
        {
            form.Blocks.map((so_block, idx) => <Box key={idx}>
                <Box m={2}>{so_block.Title}</Box>
                {
                    so_block.Blocks.map((ac_block, idx_2) => <Box key={idx_2}>
                        <Box m={2} sx={{marginLeft: 4}}>{ac_block.Title}</Box>
                        {
                            ac_block.Metrics.map((metric, idx_3) => <MetricSelectorComponent key={idx_3} metric_id={idx + "." + idx_2 + "." + idx_3} metric_name={metric.Name}/>)
                        }
                    </Box>)
                }
            </Box>)
        }
        </Box>
    </Box>
}

interface MetricSelectorComponentProps {
    metric_id: string
    metric_name: string
}

function MetricSelectorComponent({metric_id, metric_name} : MetricSelectorComponentProps) {
    let {id} = useParams()
    let assessment_id = id ? id : ""
    let metric = useSelector(selectMetric(assessment_id, metric_id))
    let dispatch = useDispatch()

    let metricValues: MetricResult = {
        "selected": true,
        "inputs": {},
        "outputs": {},
        "target_1": 0,
        "target_2": 0,
        "target_3": 0,
        "min_value": 0,
        "max_value": 0,
        "ref_1_value": 0,
        "ref_2_value": 0,
        "feedback": Array<string>(),
    }

    if (metric) {
        metricValues = {
            ...metricValues,
            ...metric
        }
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        metricValues["selected"] = !metricValues["selected"]
        dispatch(updateMetric(assessment_id, metric_id, metricValues))
    }

    return <Box key={metric_id}>
        <Box m={1} sx={{marginLeft: 6}}>
            <FormControlLabel control={<Checkbox inputProps={{ 'aria-label': metric_name }} checked={metricValues["selected"]} onChange={handleChange} />} label={metric_name}/>
        </Box>
    </Box>
}