import * as React from "react";
import {DashboardLayout} from "../../../components/dashboard/dashboard-layout";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {getUser, User} from "../../../data/user";
import {NotAuthenticatedError} from "../../../auth/auth_service";
import {useAuth} from "../../../auth/auth_provider";
import {Box, CircularProgress} from "@mui/material";

export function DashboardUserPage() {
    let nav = useNavigate();
    let auth = useAuth();
    let [currentUser, setCurrentUser] = useState<User | null>(null)
    let { id } = useParams();

    useEffect(() => {
        if (id !== undefined) {
            getUser(id).then((data: User) => {
                setCurrentUser(data)
            }).catch((e) => {
                if (e instanceof NotAuthenticatedError) {
                    nav("/login")
                }
                if (e.message.includes("401")) {
                    auth.signout(() => nav('/login'))
                } else {
                    console.error(e)
                }
            })
        }
    }, [id, nav, auth])

    return (
        <div>
            <DashboardLayout pageTitle={"User details"}>
                {
                    currentUser ? <div>
                        <h1>{currentUser.first_name + " " + currentUser.last_name}</h1>
                    </div> : <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "600px" }}>
                        <Box sx={{display:"flex", flexDirection: "column"}}>
                            <CircularProgress color="info" />
                            <Box marginY={2}>Loading...</Box>
                        </Box>
                    </Box>
                }
            </DashboardLayout>
        </div>
    );
}