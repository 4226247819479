import {
    Alert,
    Box,
    Button, Link, Snackbar,
    Tab,
    Tabs
} from "@mui/material";
import React, {useState} from "react";
import {FormBlock, FrameworkAssessmentForm} from "../../data/framework";
import {AssessmentFormBlock} from "./assessment-form-block";
import {Assessment, updateAssessment} from "../../data/assessment";
import {AFFeedbackComponent} from "./assessment-form-af-feedback";
import store from './../../store';
import {useParams} from "react-router-dom";
import {FrameworkTree} from "./assessment-form-framework-tree";
import {FeedbackQuestionComponent} from "./assessment-form-feedback-question-component";
import {useDispatch, useSelector} from "react-redux";
import {selectACFeedbacks, selectSOFeedbacks} from "../../store/selectors/assessment-results";
import {updateACFeedback, updateSOFeedback} from "../../store/actions/assessment-results";

export interface AssessmentGeneratedFormProps {
    assessment: Assessment
    form: FrameworkAssessmentForm
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export interface SnackbarNotification {
    type: "info" | "error" | "success"
    msg: string
}

export function AssessmentGeneratedForm({assessment, form}: AssessmentGeneratedFormProps) {
    let {id} = useParams();
    let assessment_id = id ? id : ""
    let [tabIdx, setTabIdx] = useState(0);
    let [initialFeedback, setInitialFeedback] = useState<any>(assessment.results ? (assessment.results.initial_feedback ? assessment.results.initial_feedback : []) : []); // ToDo add persistence in local storage - need also to deal with when to delete it (have a routine on page change?)
    let [secondaryFeedback, setSecondaryFeedback] = useState<any>(assessment.results ? (assessment.results.secondary_feedback ? assessment.results.secondary_feedback : []) : []); // ToDo add persistence in local storage - need also to deal with when to delete it (have a routine on page change?)
    let [notification, setNotification] = useState<SnackbarNotification | null>(null);

    if (form === undefined) {
        return <p>Loading...</p>
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIdx(newValue);
    };

    function updateFeedback(type: "initial" | "secondary") {
        if (type === "initial") {
            return function (idx: number, value: any) {
                let newFeedback = [...initialFeedback]
                newFeedback[idx] = value
                setInitialFeedback(newFeedback)
            }
        }
        return function (idx: number, value: any) {
            let newFeedback = [...secondaryFeedback]
            newFeedback[idx] = value
            setSecondaryFeedback(newFeedback)
        }
    }

    document.onkeydown = (e) => {
        if ((e.ctrlKey && e.key === 's') || (e.metaKey && e.key === 's')) {
            e.preventDefault();
            // ToDo: Validate form here maybe... now it bypasses validation
            save()
        }
    }

    function saveResults(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        save()
    }

    function save() {
        let result = store.getState().assessmentResults[assessment_id];
        if (typeof result === "undefined") {
            result = {
                // @ts-ignore
                metrics: {},
                // @ts-ignore
                ac_feedbacks: {},
                // @ts-ignore
                so_feedbacks: {}
            }
        }
        // @ts-ignore
        let metrics = result.metrics
        // @ts-ignore
        let ac_feedbacks = result.ac_feedbacks
        // @ts-ignore
        let so_feedbacks = result.so_feedbacks
        const updateForm = {
            "Name": assessment.Name,
            "Label": assessment.Label,
            "results": {
                metrics: metrics,
                ac_feedbacks: ac_feedbacks,
                so_feedbacks: so_feedbacks,
                initial_feedback: initialFeedback,
                secondary_feedback: secondaryFeedback
            }
        }
        updateAssessment(assessment.id, updateForm).then(() => setNotification({
            type: "success", msg: "Information successfully saved!"
        })).catch((e) => {
            console.log("[Error]: ", e)
            setNotification({
                type: "error", msg: "An error occurred while saving the assessment"
            })
        })
    }

    return <Box>
        <h1>{assessment.Name} {assessment.Label !== "" ? " - " + assessment.Label : ""}</h1>
        <form onSubmit={saveResults}>
            <p>{form.Description}</p>
            {
                form.Blocks ? <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabIdx} onChange={handleChange} aria-label="top level tabs"
                              variant="scrollable"
                              scrollButtons="auto">
                            <Tab key={0}
                                 label={"Framework"} {...a11yProps(0)}/>
                            {
                                form.Blocks.map((b, idx) => <Tab key={idx + 1}
                                                                 label={b.Title} {...a11yProps(idx + 1)}/>)
                            }
                            <Tab key={form.Blocks.length + 1}
                                 label={"Feedback on the overall BWS framework - A"} {...a11yProps(form.Blocks.length + 1)}/>
                            <Tab key={form.Blocks.length + 2}
                                 label={"Feedback on the overall BWS framework - B"} {...a11yProps(form.Blocks.length + 2)}/>
                        </Tabs>
                    </Box>
                    <TabPanel key={0} value={tabIdx} index={0}>
                        <FrameworkTree form={form}/>
                    </TabPanel>
                    {
                        form.Blocks.map((b, idx) =>
                            <TabPanel key={idx + 1} value={tabIdx} index={idx + 1}>
                                <p>{b.Description}</p>
                                {
                                    b.Blocks ? b.Blocks.map((bb, idx2) => <AssessmentFormBlock key={idx2} block={bb}
                                                                                               idx={idx2}
                                                                                               parentIdx={idx + ""}
                                        />) :
                                        <div/>
                                }
                                <SOFeedbackComponent assessment_id={assessment_id} so_id={idx + ""} so_block={b}/>
                            </TabPanel>)
                    }
                    <TabPanel key={form.Blocks.length + 1} value={tabIdx} index={form.Blocks.length + 1}>
                        <AFFeedbackComponent feedback_questions={form.initial_feedback} type={"initial"}
                                             updateFunc={updateFeedback("initial")} state={initialFeedback}/>
                    </TabPanel>
                    <TabPanel key={form.Blocks.length + 2} value={tabIdx} index={form.Blocks.length + 2}>
                        <AFFeedbackComponent feedback_questions={form.secondary_feedback} type={"secondary"}
                                             updateFunc={updateFeedback("secondary")} state={secondaryFeedback}/>
                    </TabPanel>
                </Box> : <div/>
            }
            <Box sx={{display: "flex", flexDirection: "row-reverse", m: 3}}>
                <Button type={"submit"} variant={"outlined"}>Save</Button>
            </Box>
        </form>
        <Snackbar open={notification !== null} onClose={() => setNotification(null)} autoHideDuration={5000}
                  anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert severity={notification?.type} onClose={() => setNotification(null)}>
                {notification?.msg}
            </Alert>
        </Snackbar>
    </Box>
}

interface SOFeedbackProps {
    assessment_id: string
    so_id: string
    so_block: FormBlock
}

function SOFeedbackComponent({assessment_id, so_id, so_block}: SOFeedbackProps) {
    let [showFeedback, setShowFeedback] = useState(false);
    let so_feedbacks = useSelector(selectSOFeedbacks(assessment_id, so_id))
    let dispatch = useDispatch();

    function updateSOFeedbackValue(feedback_id: number, value: any) {
        dispatch(updateSOFeedback(assessment_id, so_id, feedback_id, value))
    }

    if (!so_block.Feedback || so_block.Feedback.length === 0) {
        return <div/>
    }

    return <Box>
        {
            so_block.Feedback && so_block.Feedback.length > 0 ? <div>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Box><h5>SO Feedback</h5></Box>
                    <Box marginX={1}><Link sx={{cursor: "pointer"}}
                                           onClick={() => setShowFeedback(!showFeedback)}>{showFeedback ? "hide" : "see"}</Link></Box>
                </Box>
                {
                    showFeedback ? <Box sx={{display: "flex", flexDirection: 'column'}}>
                        {
                            so_block.Feedback.map((feedback_question, idx) =>
                                <FeedbackQuestionComponent key={idx} idx={idx} fbck_question={feedback_question}
                                                           value={so_feedbacks ? so_feedbacks[idx] : ""}
                                                           updateFunc={updateSOFeedbackValue}
                                />
                            )
                        }
                    </Box> : <Box/>
                }
            </div> : <div/>
        }
    </Box>
}