import {Box, Link} from "@mui/material";
import * as React from "react";
import {Assessment} from "../../data/assessment";
import {FeedbackQuestion, FormBlock, Framework, Metric} from "../../data/framework";
import {useSelector} from "react-redux";
import {selectACFeedbacks, selectMetric, selectSOFeedbacks} from "../../store/selectors/assessment-results";
import {useState} from "react";

export function SOFeedback({
                               as_id,
                               so_id,
                               so_block,
                               framework
                           }: { as_id: string, so_id: number, so_block: FormBlock, framework: Framework }) {
    let so_feedbacks = useSelector(selectSOFeedbacks(as_id, so_id + ""))
    let [showFeedback, setShowFeedback] = useState(false);

    return <Box>
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Box><h3>{framework.AssessmentForm.Blocks[so_id].Title}</h3></Box>
            <Box marginX={1}><Link sx={{cursor: "pointer"}}
                                   onClick={() => setShowFeedback(!showFeedback)}>{showFeedback ? "hide" : "see"}</Link></Box>
        </Box>
        {
            !showFeedback ? <div/> : <Box>
                {
                    so_feedbacks && so_feedbacks.length > 0 ? <Box>
                        <h4>SO Feedbacks</h4>
                        {
                            so_block.Feedback.map((fbck, fbck_id) => <Box key={fbck_id}>
                                <FeedbackQuestionResult question={fbck}
                                                        result={so_feedbacks ? so_feedbacks[fbck_id] : undefined}/>
                            </Box>)
                        }
                    </Box> : <div/>
                }
                {
                    so_block.Blocks.map((ac_block, ac_id) => <ACFeedback key={ac_id} as_id={as_id} ac_id={so_id + "." + ac_id}
                                                                         ac_block={ac_block} framework={framework}/>)
                }
                <Box>

                </Box>
            </Box>
        }
    </Box>
}

function ACFeedback({
                        as_id,
                        ac_id,
                        ac_block,
                        framework
                    }: { as_id: string, ac_id: string, ac_block: FormBlock, framework: Framework }) {

    let ac_feedbacks = useSelector(selectACFeedbacks(as_id, ac_id))

    return <Box>
        <h4>{ac_block.Title}</h4>
        {
            ac_feedbacks && ac_feedbacks.length > 0 ? <Box>
                <h5>AC Feedbacks</h5>
                {
                    ac_block.Feedback.map((fbck, fbck_id) => <Box>
                        <FeedbackQuestionResult key={fbck_id} question={fbck}
                                                result={ac_feedbacks ? ac_feedbacks[fbck_id] : undefined}/>
                    </Box>)
                }
                {
                    ac_block.Metrics.map((metric, metric_id) => <MetricFeedback key={metric_id} as_id={as_id} metricKey={ac_id + "." + metric_id} metric={metric}/>)
                }
            </Box> : <p>No feedback available</p>
        }
    </Box>
}


interface MetricFeedbackProps {
    as_id: string
    metricKey: string
    metric: Metric
}

function MetricFeedback({as_id, metricKey, metric}: MetricFeedbackProps) {
    let metricResults = useSelector(selectMetric(as_id, metricKey))

    let NoFeedbackAvailable =  <Box key={metricKey}>
        <h5>{metric.Name}</h5>
        <em>No feedback available</em>
    </Box>

    let NotSelected =  <Box key={metricKey}>
        <h5>{metric.Name}</h5>
        <em>Metric not selected</em>
    </Box>

    if (!metricResults || !metricResults.feedback || metricResults.feedback.length === 0) {
       return NoFeedbackAvailable
    }

    if (!metricResults.selected) {
        return NotSelected
    }

    return <Box key={metricKey}>
        <h5>{metric.Name}</h5>
        {
            metric.Feedback.map((fbck, fbck_id) =>
                <FeedbackQuestionResult key={fbck_id} question={fbck}
                                        result={metricResults.feedback ? metricResults.feedback[fbck_id] : undefined}/>
            )
        }
    </Box>
}

function FeedbackQuestionResult({question, result}: { question: FeedbackQuestion, result: any }) {
    let inner = <strong>Type "{question.Type}" not supported</strong>

    if (question.Type === "description") {
        return <Box marginY={3}>
            <strong>Note:</strong><span>{question.Question}</span><br/>
        </Box>
    }

    if (!result) {
        inner = <Box>
            <em>Question: {question.Question}</em><br/>
            <p>Answer: N/A</p>
        </Box>
    } else {
        switch (question.Type) {
            case "text":
                inner = <Box>
                    <em>Question: {question.Question}</em><br/>
                    <p>Answer: {result["text"] ? result["text"] : "N/A"}</p>
                </Box>
                break
            case "choice+text":
                inner = <Box>
                    <p><em>Question: {question.Question}</em></p>
                    <p>Choice: {result["choice"]}</p>
                    <p>Answer: {result["text"] ? result["text"] : "N/A"}</p>
                </Box>
                break
            case "choice":
                inner = <Box>
                    <p><em>Question: {question.Question}</em></p>
                    <p>Choice: {result["choice"]}</p>
                </Box>
                break
            case "multiple-choice+text":
                inner = <Box>
                    <p><em>Question: {question.Question}</em></p>
                    <p>Choice(s):</p>
                    <ul>
                        {
                            Object.keys(question.Options).map((options, options_idx) => result["choices"][options_idx] ? <li key={options_idx}>{options}</li> : <div key={options_idx}/>)
                        }
                    </ul>
                    <p>Additional answer: {result["text"] ? result["text"] : "N/A"}</p>
                </Box>
                break
        }
    }

    return <Box marginY={3}>
        {inner}
    </Box>
}

interface AssessmentFeedbackProps {
    assessment: Assessment
    framework: Framework | null
}

export function AssessmentFeedback({assessment, framework}: AssessmentFeedbackProps) {
    let initial_feedback_results = assessment.results?.initial_feedback
    let secondary_feedback_results = assessment.results?.secondary_feedback
    let [showInitialFeedback, setShowInitialFeedback] = useState(false);
    let [showSecondaryFeedback, setShowSecondaryFeedback] = useState(false);

    return <Box>{
        assessment.results ? <Box>
            {
                framework?.AssessmentForm.Blocks.map((block, idx) => <SOFeedback key={idx} as_id={assessment.id}
                                                                                 so_id={idx} so_block={block}
                                                                                 framework={framework}/>)
            }
            {
                <Box>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <Box><h3>Feedback on the overall BWS framework - A</h3></Box>
                        <Box marginX={1}><Link sx={{cursor: "pointer"}}
                                               onClick={() => setShowInitialFeedback(!showInitialFeedback)}>{showInitialFeedback ? "hide" : "see"}</Link></Box>
                    </Box>
                    {
                        showInitialFeedback ? <Box>
                            {
                                initial_feedback_results ? framework?.AssessmentForm.initial_feedback.map((fdbk, fdck_idx) =>
                                    <FeedbackQuestionResult key={fdck_idx} question={fdbk} result={assessment.results?.initial_feedback[fdck_idx]}/>
                                ) : <p>No feedback</p>
                            }
                        </Box> : <div/>
                    }
                </Box>
            }
            {
                <Box>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <Box><h3>Feedback on the overall BWS framework - B</h3></Box>
                        <Box marginX={1}><Link sx={{cursor: "pointer"}}
                                               onClick={() => setShowSecondaryFeedback(!showSecondaryFeedback)}>{showSecondaryFeedback ? "hide" : "see"}</Link></Box>
                    </Box>
                    {
                        showSecondaryFeedback ? <Box>
                            {
                                secondary_feedback_results ? framework?.AssessmentForm.secondary_feedback.map((fdbk, fdck_idx) =>
                                    <FeedbackQuestionResult key={fdck_idx} question={fdbk} result={assessment.results?.secondary_feedback[fdck_idx]}/>
                                ) : <p>No feedback</p>
                            }
                        </Box> : <div/>
                    }
                </Box>
            }
        </Box> : <p>No results registered so far</p>
    }</Box>
}