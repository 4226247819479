import * as React from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ChangeEvent, useState} from "react";
import {createUser} from "../../data/user";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

interface AddUserDialogProps {
    open: boolean
    exitCallback: (data?: any) => void
}

function AddUserDialog(props: AddUserDialogProps) {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    const [errorMessage, setErrorMessage] = useState("")
    const [serverErrorMessage, setServerErrorMessage] = useState("")

    function onFirstNameChanged(event: ChangeEvent<HTMLInputElement>) {
        setFirstName(event.target.value)
        setErrorMessage("")
        setServerErrorMessage("")
    }

    function onLastNameChanged(event: ChangeEvent<HTMLInputElement>) {
        setLastName(event.target.value)
        setErrorMessage("")
        setServerErrorMessage("")
    }

    function onEmailChanged(event: ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value)
        setErrorMessage("")
        setServerErrorMessage("")
    }

    function onPasswordChanged(event: ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value)
        setErrorMessage("")
        setServerErrorMessage("")
    }

    function onIsAdminChanged(event: ChangeEvent<HTMLInputElement>) {
        setIsAdmin(!isAdmin)
        setErrorMessage("")
        setServerErrorMessage("")
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        createUser({
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            is_admin: isAdmin
        }).then((newUser) => {
            setFirstName("")
            setLastName("")
            setEmail("")
            setPassword("")
            setIsAdmin(false)
            props.exitCallback(newUser)
        }).catch((e) => {
            console.error("Could not create a new user: ", e)
            setServerErrorMessage(e)
        })
    }

    return (
        <Dialog open={props.open} onClose={() => props.exitCallback(null)} aria-labelledby="form-dialog-new-user" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">New User</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the user information below
                    </DialogContentText>
                    {
                        serverErrorMessage !== "" ?
                            <DialogContentText style={{color: "red"}}>
                                Error: {serverErrorMessage}
                            </DialogContentText> : null
                    }
                    <TextField
                        required
                        margin="dense"
                        id="first_name"
                        label="First name"
                        type="text"
                        fullWidth
                        placeholder="John"
                        variant="outlined"
                        onChange={onFirstNameChanged}
                        defaultValue={firstName}
                        error={errorMessage !== ""}
                        helperText={errorMessage !== "" ? errorMessage : null}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="last_name"
                        label="Last name"
                        type="text"
                        fullWidth
                        placeholder="Doe"
                        variant="outlined"
                        onChange={onLastNameChanged}
                        defaultValue={lastName}
                        error={errorMessage !== ""}
                        helperText={errorMessage !== "" ? errorMessage : null}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="email"
                        label="Email"
                        type="text"
                        fullWidth
                        placeholder="john.doe@example.com"
                        variant="outlined"
                        onChange={onEmailChanged}
                        defaultValue={email}
                        error={errorMessage !== ""}
                        helperText={errorMessage !== "" ? errorMessage : null}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        onChange={onPasswordChanged}
                        defaultValue={password}
                        error={errorMessage !== ""}
                        helperText={errorMessage !== "" ? errorMessage : null}
                    />
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={isAdmin} onChange={onIsAdminChanged}/>} label="Create as administrator" />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.exitCallback(null)} color="primary">
                        Cancel
                    </Button>
                    <Button type={"submit"} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddUserDialog;