import {Box, Divider, Drawer} from '@mui/material';
import {ChartBar as ChartBarIcon} from '../../icons/chart-bar';
import {User as UserIcon} from '../../icons/user';
import {Link} from "react-router-dom";
import {NavItem} from "./nav-item";
import BallotIcon from '@mui/icons-material/Ballot';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {useAuth} from "../../auth/auth_provider";
import SINTEFLogo from '../../logos/sintef_logo_white.png';
const commonItems = [
    {
        href: '/home',
        icon: (<ChartBarIcon fontSize="small"/>),
        title: 'Home'
    },
];

const administrationItems = [
    {
        href: '/home/users',
        icon: (<UserIcon fontSize="small"/>),
        title: 'Users'
    },
    {
        href: '/home/frameworks',
        icon: (<WidgetsIcon fontSize="small"/>),
        title: 'Frameworks'
    },
];

const userItems = [
    {
        href: '/home/assessments',
        icon: (<BallotIcon fontSize="small"/>),
        title: 'My assessments'
    },
];

export const DashboardSidebar = () => {
    const auth = useAuth();

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <div>
                    <Box sx={{p: 3}}>
                        <Link to="/">
                            <img  src={SINTEFLogo} style={{width: "220px"}} alt="sintef logo"/>
                        </Link>
                    </Box>
                </div>
                <Divider
                    sx={{
                        borderColor: '#2D3748',
                        my: 3
                    }}
                />
                <Box>
                    {commonItems.map((item) => (
                        <NavItem
                            key={item.title}
                            icon={item.icon}
                            href={item.href}
                            title={item.title}
                        />
                    ))}
                </Box>
                {
                    auth.user.roles.includes("ROLE_ADMIN") ?
                        <div>
                            <Divider
                                sx={{
                                    borderColor: '#2D3748',
                                    my: 3
                                }}
                            />
                            <Box>
                                {administrationItems.map((item) => (
                                    <NavItem
                                        key={item.title}
                                        icon={item.icon}
                                        href={item.href}
                                        title={item.title}
                                    />
                                ))}
                            </Box>
                        </div> : <div/>
                }

                <Divider
                    sx={{
                        borderColor: '#2D3748',
                        my: 3
                    }}
                />
                <Box>
                    {userItems.map((item) => (
                        <NavItem
                            key={item.title}
                            icon={item.icon}
                            href={item.href}
                            title={item.title}
                        />
                    ))}
                </Box>
                <Divider
                    sx={{
                        borderColor: '#2D3748',
                        my: 3
                    }}
                />
            </Box>
        </>
    );

    return (
        <Drawer
            anchor="left"
            open
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: 280
                }
            }}
            variant="permanent"
        >
            {content}
        </Drawer>
    );
};