import {Accordion, AccordionDetails, AccordionSummary, Box, Link} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {FormBlock} from "../../data/framework";
import {AssessmentMetric} from "./assessment-form-metric";
import {FeedbackQuestionComponent} from "./assessment-form-feedback-question-component";
import {useDispatch, useSelector} from "react-redux";
import {selectACFeedbacks} from "../../store/selectors/assessment-results";
import {useParams} from "react-router-dom";
import {updateACFeedback} from "../../store/actions/assessment-results";


export function AssessmentFormBlock({block, idx, parentIdx}: { block: FormBlock, idx: number, parentIdx: string }) {
    let {id} = useParams();
    let assessment_id = id ? id : ""
    const blockKey = parentIdx + "." + idx
    let [showFeedback, setShowFeedback] = useState(false);
    let ac_feedbacks = useSelector(selectACFeedbacks(assessment_id, blockKey))
    let dispatch = useDispatch();

    function updateACFeedbackValue(feedback_id: number, value: any) {
        dispatch(updateACFeedback(assessment_id, blockKey, feedback_id, value))
    }

    return <Accordion variant={"outlined"} TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls={"block-" + idx + "-content"}
            id={"block-" + idx}
        >
            <Typography component={"h2"}>{idx + 1} - {block.Title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <strong>Description:</strong><br/>
            {block.Description.split("\n").map((line, lIdx) => <p key={lIdx}>{line}</p>)}
            {block.Metrics.map((m, mIdx) => <AssessmentMetric key={mIdx} idx={mIdx} parentIdx={blockKey} metric={m}/>)}
            {
                block.Feedback && block.Feedback.length > 0 ? <div>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <Box><h5>AC Feedback</h5></Box>
                        <Box marginX={1}><Link sx={{cursor: "pointer"}}
                                               onClick={() => setShowFeedback(!showFeedback)}>{showFeedback ? "hide" : "see"}</Link></Box>
                    </Box>
                    {
                        showFeedback ? <Box sx={{display: "flex", flexDirection: 'column'}}>
                            {
                                block.Feedback.map((feedback_question, idx) =>
                                    <FeedbackQuestionComponent key={idx} idx={idx} fbck_question={feedback_question}
                                        value={ac_feedbacks ? ac_feedbacks[idx] : ""}
                                        updateFunc={updateACFeedbackValue}
                                    />
                                )
                            }
                        </Box> : <Box/>
                    }
                </div> : <div/>
            }
        </AccordionDetails>
    </Accordion>
}