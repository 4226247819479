import * as React from "react";
import {DashboardLayout} from "../../../components/dashboard/dashboard-layout";
import {useEffect, useState} from "react";
import {Framework, getAllFrameworks} from "../../../data/framework";
import {useNavigate} from "react-router-dom";
import {Box, Button} from "@mui/material";
import AddFrameworkDialog from "../../../components/dialogs/add-framework-dialog";
import {FrameworksTable} from "../../../components/framework/frameworks-table";
import {NotAuthenticatedError} from "../../../auth/auth_service";
import {useAuth} from "../../../auth/auth_provider";

export function DashboardFrameworksPage() {
    const nav = useNavigate();
    const auth = useAuth();
    let [frameworks, setFrameworks] = useState([]);
    let [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        getAllFrameworks().then((data) => {
            if (data) {
                setFrameworks(data)
            }
        }).catch((e) => {
            if (e instanceof NotAuthenticatedError) {
                nav("/login")
            }
            if (e.message.includes("401")) {
                auth.signout(() => nav('/login'))
            } else {
                console.error(e)
            }
        })
    }, [nav, auth])

    return (
        <DashboardLayout pageTitle={"Frameworks"}>
            <p>Here you can see all available frameworks in the FAST Application.</p>
            <FrameworksTable frameworks={frameworks}/>
            <Box marginTop={2} ><Button variant="outlined" size="small" onClick={() => {
                setDialogOpen(true)
            }}>Add framework</Button></Box>
            <AddFrameworkDialog open={dialogOpen} exitCallback={(newFramework: Framework | undefined) => {
                if (newFramework && typeof newFramework !== "undefined") {
                    // @ts-ignore
                    setFrameworks([...frameworks, newFramework])
                }
                setDialogOpen(false)
            }}/>
        </DashboardLayout>
    );
}