import * as React from "react";
import {AuthContextType, AuthService, NotAuthenticatedError} from "./auth_service";
import {getToken} from "./jwt";

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({children}: { children: React.ReactNode }) {
    let currentUser = AuthService.getCurrentUser()
    let [user, setUser] = React.useState<any>(currentUser);

    let signin = (email: string, password: string, callback: VoidFunction) => {
        return AuthService.login({email, password}).then(() => {
            let cUser = AuthService.getCurrentUser()
            setUser(cUser);
            callback();
        });
    };

    let signout = (callback: VoidFunction) => {
        return AuthService.logout().then(() => {
            setUser(null);
            callback();
        });
    };

    let value = {user, signin, signout};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export function getAuthHeader() {
    let token = getToken();
    if (token === "") {
        throw new NotAuthenticatedError("user not authenticated: missing token")
    }
    return {'Authorization': 'Bearer '.concat(token)}
}