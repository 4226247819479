import {Dispatch} from "react";
import {
    DeselectAllMetricsAction,
    LoadAssessmentResultAction, SelectAllMetricsAction,
    UpdateACFeedbackAction,
    UpdateMetricAction, UpdateSOFeedbackAction
} from "../interfaces/actions/assessment-results";
import {AssessmentResult} from "../../data/assessment-result";
import {FrameworkAssessmentForm} from "../../data/framework";

export const enum AssessmentResultsAction {
    UpdateMetric = "UPDATE_METRIC",
    SelectAllMetrics = "SELECT_ALL_METRICS",
    DeselectAllMetrics = "DESELECT_ALL_METRICS",
    UpdateACFeedback = "UPDATE_AC_FEEDBACK",
    UpdateSOFeedback = "UPDATE_SO_FEEDBACK",
    LoadAssessmentResult = "LOAD_ASSESSMENT_RESULT"
}

export function updateMetric(assessment_id: string, id: string, value: any) {
    return (dispatch: Dispatch<UpdateMetricAction>) => {
        dispatch({
            type: AssessmentResultsAction.UpdateMetric,
            assessment_id: assessment_id,
            metric_id: id,
            metric_value: value
        } as UpdateMetricAction)
    }
}

export function selectAllMetrics(assessment_id: string, form: FrameworkAssessmentForm) {
    return (dispatch: Dispatch<SelectAllMetricsAction>) => {
        dispatch({
            type: AssessmentResultsAction.SelectAllMetrics,
            assessment_id: assessment_id,
            form: form
        } as SelectAllMetricsAction)
    }
}

export function deselectAllMetrics(assessment_id: string, form: FrameworkAssessmentForm) {
    return (dispatch: Dispatch<DeselectAllMetricsAction>) => {
        dispatch({
            type: AssessmentResultsAction.DeselectAllMetrics,
            assessment_id: assessment_id,
            form: form
        } as DeselectAllMetricsAction)
    }
}

export function updateACFeedback(assessment_id: string, ac_id: string, feedback_id: number, value: any) {
    return (dispatch: Dispatch<UpdateACFeedbackAction>) => {
        dispatch({
            type: AssessmentResultsAction.UpdateACFeedback,
            assessment_id: assessment_id,
            ac_id: ac_id,
            feedback_id: feedback_id,
            feedback_value: value
        } as UpdateACFeedbackAction)
    }
}

export function updateSOFeedback(assessment_id: string, so_id: string, feedback_id: number, value: any) {
    return (dispatch: Dispatch<UpdateSOFeedbackAction>) => {
        dispatch({
            type: AssessmentResultsAction.UpdateSOFeedback,
            assessment_id: assessment_id,
            so_id: so_id,
            feedback_id: feedback_id,
            feedback_value: value
        } as UpdateSOFeedbackAction)
    }
}

export function loadAssessmentResult(assessment_id: string, value: AssessmentResult) {
    return (dispatch: Dispatch<LoadAssessmentResultAction>) => {
        console.log("Dispatching load assessment result action", value)
        dispatch({
            type: AssessmentResultsAction.LoadAssessmentResult,
            assessment_id: assessment_id,
            value: value
        } as LoadAssessmentResultAction)
    }
}