import * as React from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ChangeEvent, useState} from "react";
import {
    Box,
    FormHelperText,
} from "@mui/material";
import {createFramework} from "../../data/framework";

interface AddFrameworkDialogProps {
    open: boolean
    exitCallback: (data?: any) => void
}

function AddFrameworkDialog(props: AddFrameworkDialogProps) {

    const [name, setName] = useState("");
    const [file, setFile] = useState("");

    const [errorMessage, setErrorMessage] = useState("")
    const [serverErrorMessage, setServerErrorMessage] = useState("")

    function onContentChanged(event: ChangeEvent<HTMLInputElement>) {
        setName(event.target.value)
        setErrorMessage("")
        setServerErrorMessage("")
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", name);
        createFramework(formData).then(
            (newCampaign) => {
                setName("")
                setFile("")
                props.exitCallback(newCampaign)
            }
        ).catch(
            (e) => {
                console.error("Could not create a new campaign: ", e)
                setServerErrorMessage(e)
            }
        );
    }

    function handleUpload(event: any) {
        setFile(event.target.files[0]);
    }

    return (
        <Dialog open={props.open} onClose={() => props.exitCallback(null)} aria-labelledby="form-dialog-new-framework" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">New Framework</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the framework information below
                    </DialogContentText>
                    {
                        serverErrorMessage !== "" ?
                            <DialogContentText style={{color: "red"}}>
                                Error: {serverErrorMessage}
                            </DialogContentText> : null
                    }
                    <TextField
                        required
                        margin="dense"
                        id="Name"
                        label="Framework name"
                        type="text"
                        fullWidth
                        placeholder="Name"
                        variant="outlined"
                        onChange={onContentChanged}
                        defaultValue={name}
                        error={errorMessage !== ""}
                        helperText={errorMessage !== "" ? errorMessage : null}
                    />
                    <Box sx={{marginTop: "10px"}} ><label htmlFor="upload-json">
                        <input
                            style={{display: 'none'}}
                            id="upload-json"
                            name="upload-json"
                            type="file"
                            onChange={handleUpload}
                        />
                        <Button color="primary" variant="contained" component="span">
                            { file ? "Change file" : "Select file" }
                        </Button>
                        <FormHelperText id="upload-json-helper">{typeof file !== "string" ? file["name"] : "No file selected"}</FormHelperText>
                    </label></Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.exitCallback(null)} color="primary">
                        Cancel
                    </Button>
                    <Button type={"submit"} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddFrameworkDialog;