import {SURT_API_ADDRESS} from "../config";
import axios from "axios";
import {getAuthHeader} from "../auth/auth_provider";

export interface Role {
    name: string
}

export interface User {
    id: string
    created_at: string
    updated_at: string
    email: string
    first_name: string
    last_name: string
    roles: Array<Role>
}

export async function getAllUsers() {
    let endpoint = SURT_API_ADDRESS + "/api/v1/dashboard/users/"
    return axios.get(endpoint, {headers: getAuthHeader()}).then(
        (res) => res.data
    )
}

export async function getUser(id: string) {
    let endpoint = SURT_API_ADDRESS + "/api/v1/dashboard/users/" + id
    return axios.get(endpoint, {headers: getAuthHeader()}).then(
        (res) => res.data
    )
}

export async function createUser(data: any) {
    let endpoint = SURT_API_ADDRESS + "/api/v1/dashboard/users/"
    return axios.post(endpoint, data, {
        headers: {
            'Authorization': getAuthHeader().Authorization,
            'Content-Type': "multipart/form-data"
        }
    }).then(
        (res) => res.data
    )
}