import * as React from "react";
import {DashboardLayout} from "../../../components/dashboard/dashboard-layout";
import {useEffect, useState} from "react";
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {Box, Button, CircularProgress, LinearProgress} from "@mui/material";
import {Assessment, getAssessment} from "../../../data/assessment";
import {NotAuthenticatedError} from "../../../auth/auth_service";
import {useAuth} from "../../../auth/auth_provider";
import {Framework, getFramework} from "../../../data/framework";
import {AssessmentResults} from "../../../components/assessment/assessment-results";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import {AssessmentFeedback} from "../../../components/assessment/assessment-feedback";
import {loadAssessmentResult} from "../../../store/actions/assessment-results";
import {useDispatch} from "react-redux";

function Legend() {
    return <Box sx={{marginTop: 5}}>
        <h3>Legend</h3>
        <Box sx={{width: "50%", minWidth: "400px"}} >
            <Box sx={{display: 'flex', flexDirection: "row", alignItems: "center"}}>
                <Box m={1} sx={{width: "30%"}}>{"Res <= Ref 1"}</Box>
                <Box m={1} sx={{flex: "1"}}><LinearProgress variant="determinate" color={'error'} value={30}/></Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: "row", alignItems: "center"}}>
                <Box m={1} sx={{width: "30%"}}>{"Res <= Ref 2"}</Box>
                <Box m={1} sx={{flex: "1"}}><LinearProgress variant="determinate" color={'warning'} value={60}/></Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: "row", alignItems: "center"}}>
                <Box m={1} sx={{width: "30%"}}>{"Res > Ref 2"}</Box>
                <Box m={1} sx={{flex: "1"}}><LinearProgress variant="determinate" color={'success'} value={90}/></Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: "row", alignItems: "center"}}>
                <Box m={1} sx={{width: "30%"}}>{"No Reference"}</Box>
                <Box m={1} sx={{flex: "1"}}><LinearProgress variant="determinate" color={'info'} value={50}/></Box>
            </Box>
        </Box>
    </Box>
}

function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function DashboardAssessmentPage() {
    const nav = useNavigate();
    const auth = useAuth();
    let query = useQuery();

    let [currentAssessment, setCurrentAssessment] = useState<Assessment | null>(null)
    let [usedFramework, setUsedFramework] = useState<Framework | null>(null)
    let {id} = useParams();
    let dispatch = useDispatch();
    let assessment_id = id ? id : ""

    useEffect(() => {
        if (id !== undefined) {
            getAssessment(id).then((data: Assessment) => {
                setCurrentAssessment(data)
                if (data.results) {
                    dispatch(loadAssessmentResult(assessment_id, data.results))
                }
                getFramework(data.FrameworkId).then((framework: Framework) => {
                    setUsedFramework((framework))
                })
            }).catch((e) => {
                if (e instanceof NotAuthenticatedError) {
                    nav("/login")
                }
                if (e.message.includes("401")) {
                    auth.signout(() => nav('/login'))
                } else {
                    console.error(e)
                }
            })
        }
    }, [id, nav, auth])

    let queryPrint = query.get("print")
    let printView = queryPrint && queryPrint === "true"

    async function downloadAssessment() {
        const fileName = "fast_assessment_" + currentAssessment?.id + ".json";
        const json = JSON.stringify(currentAssessment, null, 2);
        const blob = new Blob([json], {type:'application/json'});
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName;
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div>
            {
                printView ? (currentAssessment ? <Box m={5} sx={{maxWidth: "1000px"}}>
                        <h1>{currentAssessment.Name}</h1>
                        <Box>
                            <Box marginY={1}><strong>Label:</strong> {currentAssessment.Label}</Box>
                            <Box marginY={1}><strong>Framework used:</strong> {usedFramework?.Name}</Box>
                            <Box marginY={1}><strong>Created
                                on:</strong> {new Date(currentAssessment.created_at).toDateString()}</Box>
                            <Box marginY={1}><strong>Updated
                                on:</strong> {new Date(currentAssessment.updated_at).toDateString()}</Box>
                        </Box>
                        <Box marginY={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Box sx={{width:"50%"}}><hr/></Box>
                        </Box>
                        <h2>Results</h2>
                        <AssessmentResults assessment={currentAssessment} framework={usedFramework}/>
                        <Legend/>
                        <Box marginY={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Box sx={{width:"50%"}}><hr/></Box>
                        </Box>
                        <Box marginTop={5}><h2>Feedback</h2></Box>
                        <AssessmentFeedback assessment={currentAssessment} framework={usedFramework}/>
                    </Box> : <p>Loading...</p>)
                    : <DashboardLayout pageTitle={"Assessment"}>
                        {
                            currentAssessment ? <div>
                                <h1>{currentAssessment.Name}</h1>
                                <Box marginY={1}><strong>Label:</strong> {currentAssessment.Label}</Box>
                                <Box marginY={1}><strong>Framework used:</strong> {usedFramework?.Name}</Box>
                                <Box marginY={1}><strong>Created
                                    on:</strong> {new Date(currentAssessment.created_at).toDateString()}</Box>
                                <Box marginY={1}><strong>Updated
                                    on:</strong> {new Date(currentAssessment.updated_at).toDateString()}</Box>
                                <Box>
                                    <Box m={1} marginTop={3}><Button variant="outlined" size="small" onClick={() => {
                                        nav("/home/assessments/" + currentAssessment?.id + "/edit")
                                    }}>Edit</Button></Box>
                                    <h2>Results</h2>
                                    <AssessmentResults assessment={currentAssessment} framework={usedFramework}/>
                                    <Legend/>
                                    <Box marginTop={5}><h2>Feedback</h2></Box>
                                    <AssessmentFeedback assessment={currentAssessment} framework={usedFramework}/>
                                    {currentAssessment.results ?
                                        <Box sx={{display: "flex", displayDirection: "row", marginTop: 5}}>
                                            <Box m={1}><Button variant="outlined" size="small" onClick={() => {
                                                nav("/home/assessments/" + currentAssessment?.id + "/edit")
                                            }}>Edit</Button></Box>
                                            <Box m={1}><Button startIcon={<LocalPrintshopIcon/>} variant="outlined" size="small" onClick={() => {
                                                nav("/home/assessments/" + currentAssessment?.id + "?print=true")
                                            }}>Print view</Button></Box>
                                            <Box m={1}><Button variant="outlined" size="small" onClick={downloadAssessment}>Download JSON</Button></Box>
                                        </Box> : <div/>
                                    }
                                </Box>
                            </div> : <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "600px" }}>
                                <Box sx={{display:"flex", flexDirection: "column"}}>
                                    <CircularProgress color="info" />
                                    <Box marginY={2}>Loading...</Box>
                                </Box>
                            </Box>
                        }
                    </DashboardLayout>
            }
        </div>
    );
}