import {FeedbackQuestion} from "../../data/framework";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import React from "react";

interface FeedbackQuestionComponentProps {
    idx: number
    fbck_question: FeedbackQuestion
    value: any
    updateFunc: (idx: number, value: any) => void
}

export function FeedbackQuestionComponent(props: FeedbackQuestionComponentProps) {
    if (props.fbck_question.Type === "text") {
        let previousValue = {
            text: "",
            ...props.value
        };

        return <Box key={props.idx} sx={{marginTop: "20px", marginBottom: "20px"}}>
            <FormControl>
                <FormLabel
                    id={"fdbk-question-label-" + props.idx}>{"Q" + (props.idx + 1) + ". " + props.fbck_question.Question}</FormLabel>
                <TextField id={"fdbk-question-" + props.idx} multiline placeholder={"Your answer"}
                           value={previousValue.text}
                           onChange={(event) => props.updateFunc(props.idx, {
                               text: event.target.value
                           })}
                />
            </FormControl>
        </Box>
    }

    if (props.fbck_question.Type === "choice+text") {
        let previousValue = {
            choice: null,
            text: "",
            ...props.value
        };

        return <Box key={props.idx} sx={{marginTop: "20px", marginBottom: "20px"}}>
            <FormControl>
                <FormLabel
                    id={"fdbk-question-label-" + props.idx}>{"Q" + (props.idx + 1) + ". " + props.fbck_question.Question}</FormLabel>
                <RadioGroup
                    aria-labelledby={"fdbk-question-radio-group" + props.idx}
                    //defaultValue={Object.values(props.fbck_question.Options)[0]}
                    name={"radio-buttons-group-fdbk-question-" + props.idx}
                    value={previousValue.choice}
                    onChange={(event) => props.updateFunc(props.idx, {
                        ...props.value,
                        choice: event.target.value
                    })}
                >
                    {Object.keys(props.fbck_question.Options).map((label, idx) =>
                        <FormControlLabel key={idx} value={props.fbck_question.Options[label]} control={<Radio/>}
                                          label={label}/>
                    )}
                </RadioGroup>
                <p>{props.fbck_question.Text}</p>
                <TextField id={"fdbk-question-" + props.idx + "text"} multiline placeholder={"Your answer"}
                           value={previousValue.text}
                           onChange={(event) => props.updateFunc(props.idx, {
                               ...props.value,
                               text: event.target.value
                           })}
                />
            </FormControl>
        </Box>
    }

    if (props.fbck_question.Type === "choice") {
        let previousValue = {
            choice: null,
            ...props.value
        };

        return <Box key={props.idx} sx={{marginTop: "20px", marginBottom: "20px"}}>
            <FormControl>
                <FormLabel
                    id={"fdbk-question-label-" + props.idx}>{"Q" + (props.idx + 1) + ". " + props.fbck_question.Question}</FormLabel>
                <RadioGroup
                    aria-labelledby={"fdbk-question-radio-group" + props.idx}
                    //defaultValue={Object.values(props.fbck_question.Options)[0]}
                    name={"radio-buttons-group-fdbk-question-" + props.idx}
                    value={previousValue.choice}
                    onChange={(event) => props.updateFunc(props.idx, {
                        ...props.value,
                        choice: event.target.value
                    })}
                >
                    {Object.keys(props.fbck_question.Options).map((label, idx) =>
                        <FormControlLabel key={idx} value={props.fbck_question.Options[label]} control={<Radio/>}
                                          label={label}/>
                    )}
                </RadioGroup>
            </FormControl>
        </Box>
    }

    if (props.fbck_question.Type === "multiple-choice+text") {
        let count = Object.keys(props.fbck_question.Options).length
        let previousValue = {
            choices: Array(count).fill(false),
            text: "",
            ...props.value
        };

        return <Box key={props.idx} sx={{marginTop: "20px", marginBottom: "20px"}}>
            <FormControl>
                <FormLabel
                    id={"fdbk-question-label-" + props.idx}>{"Q" + (props.idx + 1) + ". " + props.fbck_question.Question}</FormLabel>
                <FormGroup>
                    {Object.keys(props.fbck_question.Options).map((label, idx) =>
                        <FormControlLabel key={idx}
                                          control={
                                              <Checkbox checked={previousValue.choices[idx]} name={label}
                                                        onChange={(event) => {
                                                            console.log(event.target)
                                                            let newChoices = [...previousValue.choices]
                                                            newChoices[idx] = event.target.checked;
                                                            props.updateFunc(props.idx, {
                                                                ...props.value,
                                                                choices: newChoices
                                                            })
                                                        }}/>
                                          }
                                          label={label}
                        />
                    )}
                </FormGroup>
                <p>{props.fbck_question.Text}</p>
                <TextField id={"fdbk-question-" + props.idx + "text"} multiline placeholder={"Your answer"}
                           value={previousValue.text}
                           onChange={(event) => props.updateFunc(props.idx, {
                               ...props.value,
                               text: event.target.value
                           })}
                />
            </FormControl>
        </Box>
    }

    // @ts-ignore
    if (props.fbck_question.Type === "multiple-choice") {
        let count = Object.keys(props.fbck_question.Options).length
        let previousValue = {
            choices: Array(count).fill(false),
            ...props.value
        };

        return <Box key={props.idx} sx={{marginTop: "20px", marginBottom: "20px"}}>
            <FormControl>
                <FormLabel
                    id={"fdbk-question-label-" + props.idx}>{"Q" + (props.idx + 1) + ". " + props.fbck_question.Question}</FormLabel>
                <FormGroup>
                    {Object.keys(props.fbck_question.Options).map((label, idx) =>
                        <FormControlLabel key={idx}
                                          control={
                                              <Checkbox checked={previousValue.choices[idx]} name={label}
                                                        onChange={(event) => {
                                                            console.log(event.target)
                                                            let newChoices = [...previousValue.choices]
                                                            newChoices[idx] = event.target.checked;
                                                            props.updateFunc(props.idx, {
                                                                ...props.value,
                                                                choices: newChoices
                                                            })
                                                        }}/>
                                          }
                                          label={label}
                        />
                    )}
                </FormGroup>
            </FormControl>
        </Box>
    }

    if (props.fbck_question.Type === "description") {
        return <Box key={props.idx} sx={{marginTop: "20px", marginBottom: "20px"}}>
            <span>{"(Q" + (props.idx + 1) +") "}</span><strong>Note:</strong><span>{" " + props.fbck_question.Question}</span>
        </Box>
    }

    return <Box key={props.idx} sx={{marginTop: "20px", marginBottom: "20px"}}>
        {"Unsupported type feedback question type: " + props.fbck_question.Type}
    </Box>

}