import {SURT_API_ADDRESS} from "../config";
import axios from "axios";
import {getAuthHeader} from "../auth/auth_provider";
import {AssessmentResult} from "./assessment-result";

export interface Assessment {
    id: string
    Name: string
    Label: string
    created_at: string
    updated_at: string
    FrameworkId: string
    results: AssessmentResult
}

export async function getAllAssessments() {
    let endpoint = SURT_API_ADDRESS + "/api/v1/dashboard/assessments/"
    return axios.get(endpoint, {headers: getAuthHeader()}).then(
        (res) => res.data
    )
}

export async function getAssessment(id: string) {
    let endpoint = SURT_API_ADDRESS + "/api/v1/dashboard/assessments/" + id
    return axios.get(endpoint, {headers: getAuthHeader()}).then(
        (res) => res.data
    )
}

export async function createAssessment(data: FormData) {
    let endpoint = SURT_API_ADDRESS + "/api/v1/dashboard/assessments/"
    return axios.post(endpoint, data, {
        headers: {
            'Authorization': getAuthHeader().Authorization,
            'Content-Type': "multipart/form-data"
        }
    }).then(
        (res) => res.data
    )
}

export async function updateAssessment(id: string, data: any) {
    let endpoint = SURT_API_ADDRESS + "/api/v1/dashboard/assessments/" + id
    return axios.put(endpoint, data, {
        headers: {
            'Authorization': getAuthHeader().Authorization,
            'Content-Type': "application/json"
        }
    }).then(
        (res) => res.data
    )
}