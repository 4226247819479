import {SURT_API_ADDRESS} from "../config";
import axios from "axios";
import {getAuthHeader} from "../auth/auth_provider";

export interface CalculatedField {
    Name: string
    Description: string
    Formula: string
}

export interface InputField {
    Name: string
    Description: string
    Unit: string
    Type: "number" | "choice"
    MinValue: number
    MaxValue: number
    Options: { [key: string]: number }
}

export interface FeedbackQuestion {
    Question: string
    Type: "text" | "multiple-choice+text" | "choice+text" | "choice" | "description"
    Options: { [key: string]: string | number }
    Text: string
}

export interface Metric {
    Name: string
    Description: string
    Link: string
    Unit: string
    ReversedScale: boolean
    InputFields: Array<InputField>
    CalculatedFields: Array<CalculatedField>
    Feedback: Array<FeedbackQuestion>
}

export interface FormBlock {
    Title: string
    Description: string
    Blocks: Array<FormBlock>
    Metrics: Array<Metric>
    Feedback: Array<FeedbackQuestion>
}

export interface FrameworkAssessmentForm {
    Description: string
    Blocks: Array<FormBlock>
    initial_feedback: Array<FeedbackQuestion>
    secondary_feedback: Array<FeedbackQuestion>
}

export interface Framework {
    id: string
    Name: string
    created_at: string
    updated_at: string
    AssessmentForm: FrameworkAssessmentForm
}

export function extractMetricsKeys(form: FrameworkAssessmentForm) {
    let allMetricsKeys: Array<string> = []
    form.Blocks.forEach((so_block, idx) => {
        so_block.Blocks.forEach((ac_block, idx2) => {
            ac_block.Metrics.forEach((_, idx3) => {
                allMetricsKeys.push(idx + "." + idx2 + "." + idx3)
            })
        })
    })
    return allMetricsKeys
}

export async function getAllFrameworks() {
    let endpoint = SURT_API_ADDRESS + "/api/v1/dashboard/frameworks/"
    return axios.get(endpoint, {headers: getAuthHeader()}).then(
        (res) => res.data
    )
}

export async function getFramework(id: string) {
    let endpoint = SURT_API_ADDRESS + "/api/v1/dashboard/frameworks/" + id
    return axios.get(endpoint, {headers: getAuthHeader()}).then(
        (res) => res.data
    )
}

export async function createFramework(data: FormData) {
    let endpoint = SURT_API_ADDRESS + "/api/v1/dashboard/frameworks/"
    return axios.post(endpoint, data, {
        headers: {
            'Authorization': getAuthHeader().Authorization,
            'Content-Type': "multipart/form-data"
        }
    }).then(
        (res) => res.data
    )
}