import {Box, LinearProgress} from "@mui/material";
import * as React from "react";
import {Assessment} from "../../data/assessment";
import {AssessmentResult, MetricResult} from "../../data/assessment-result";
import {FormBlock, Framework, Metric} from "../../data/framework";

interface MetricResultsProps {
    metricKey: string
    metric: Metric
    results: MetricResult
}

function MetricResults({metricKey, metric, results}: MetricResultsProps) {

    const notEnoughData = <Box key={metricKey}>
        <h5>{metricKey} - {metric.Name}</h5>
        <em>Not enough data</em>
    </Box>

    const notSelected = <Box key={metricKey}>
        <h5>{metricKey} - {metric.Name}</h5>
        <em>Not selected</em>
    </Box>

    if (!results || results.outputs.length > 0) {
        return notEnoughData
    }

    const target_1 = results.target_1;
    const target_2 = results.target_2;
    const target_3 = results.target_3;
    let min_value = results.min_value;
    let max_value = results.max_value;
    const ref_1 = results.ref_1_value;
    const ref_2 = results.ref_2_value;

    if (!min_value && !max_value) {
        return notEnoughData
    }

    const normalise = (value: number) => {
        let res = ((value - min_value) * 100) / (max_value - min_value)
        if (metric.ReversedScale) {
            min_value = results.max_value
            max_value = results.min_value
            res = ((max_value - value) * 100) / (max_value - min_value)
        }
        return Math.round(res * 100) / 100;
    }

    const getColor = (value: number) => {
        // if there is no reference values, we return the blue color
        if (ref_1 === 0 && ref_2 === 0) {
            return 'info'
        }

        if (metric.ReversedScale) {
            if (value > normalise(ref_2)) {
                return 'success'
            } else if (value > normalise(ref_1)) {
                return 'warning'
            } else {
                return 'error'
            }
        }

        if (value <= normalise(ref_1)) {
            return 'error'
        } else if (value <= normalise(ref_2)) {
            return 'warning'
        } else {
            return 'success'
        }
    }

    const output = results.outputs[Object.keys(results.outputs)[0]];
    if (!output) {
        return notEnoughData
    }

    if (!results["selected"]) {
        return notSelected
    }

    return <Box key={metricKey}>
        <h5>{metricKey} - {metric.Name}</h5>
        <Box sx={{display: 'flex', flexDirection: "row", alignItems: "center"}}>
            {
                Object.keys(results.outputs).length > 0 ?
                    Object.keys(results.outputs).map((output_key, output_idx) => <Box m={1} key={output_idx}>{output_key}: {results.outputs[output_key] + " (unit: " + metric.Unit + ")"}</Box>): <div/>
            }
        </Box>
        <Box sx={{display: 'flex', flexDirection: "row", alignItems: "center"}}>
            <Box m={1} sx={{flex: "1"}}><LinearProgress variant="determinate" color={getColor(normalise(output))}
                                                        value={normalise(output)}/></Box>
            <Box m={1}>{normalise(output) + "%"}</Box>
        </Box>
        <Box sx={{display: 'flex', flexDirection: "row", alignItems: "center"}}>
            {
                (ref_1 === 0 && ref_2 === 0) ? <div/> : <Box sx={{display: 'flex', flexDirection: "row", alignItems: "center"}}>
                    <Box m={1}>Reference value for acceptable performance: {normalise(ref_1) + "%"}</Box>
                    <Box m={1}>Reference value for good performance: {normalise(ref_2) + "%"}</Box>
                    <Box marginX={1} m={1}> / </Box>
                </Box>
            }
            <Box m={1}>T1: {normalise(target_1) + "%"}</Box>
            <Box m={1}>T2: {normalise(target_2) + "%"}</Box>
            <Box m={1}>T3: {normalise(target_3) + "%"}</Box>
        </Box>
    </Box>
}


function GetAvgForAC(results: AssessmentResult, ac: FormBlock, idx: string) {
    // need to average the SO in block
    let sum = 0;
    let count = 0;
    ac.Blocks.forEach((so, idx2) => {
        let avg = GetAvgForSO(results, so, idx + "." + idx2)
        sum += avg ? avg : 0
        count += 1
    })
    return Math.round((sum / count) * 100) / 100;
}

function GetAvgForSO(results: AssessmentResult, so: FormBlock, idx: string) {
    // Need to average the outputs
    let sum = 0;
    let count = 0;
    so.Metrics.forEach((m, idx2) => {
        let mIdx = idx + "." + idx2
        if (Object.keys(results.metrics).includes(mIdx)) {
            if (!results.metrics[mIdx].selected) {
                return; // could be replaced by a filter maybe...
            }
            if (Object.keys(results.metrics[mIdx].outputs).length > 0) {
                let res = results.metrics[mIdx]
                let min_value = res.min_value
                let max_value = res.max_value
                let output = res.outputs[Object.keys(results.metrics[mIdx].outputs)[0]]

                let normalisedOutput = ((output - min_value) * 100) / (max_value - min_value)
                if (m.ReversedScale) {
                    let min_value = res.max_value
                    let max_value = res.min_value
                    normalisedOutput = ((max_value - output) * 100) / (max_value - min_value)
                }
                sum += Math.round(normalisedOutput * 100) / 100;
            }
        }
        count += 1;
    })

    let res = Math.round((sum / count) * 100) / 100
    if (!isFinite(res) || isNaN(res)) {
        return undefined
    }

    return res;
}

interface AssessmentResultsProps {
    assessment: Assessment
    framework: Framework | null
}

export function AssessmentResults({assessment, framework}: AssessmentResultsProps) {

    const getPercentAc = (results: AssessmentResult, ac: FormBlock, idx: string) => {
        const avg = GetAvgForAC(results, ac, idx)
        if (typeof avg === "undefined" || isNaN(avg) || !isFinite(avg)) {
            return "N/A"
        }
        return avg + "%"
    }

    const getPercentSo = (results: AssessmentResult, so: FormBlock, idx: string) => {
        const avg = GetAvgForSO(results, so, idx)
        if (typeof avg === "undefined" || isNaN(avg) || !isFinite(avg)) {
            return "N/A"
        }
        return avg + "%"
    }

    return <Box>{
        assessment.results && assessment.results.metrics ? <Box>
            {
                framework?.AssessmentForm.Blocks.map((block, idx) => <Box key={idx}>
                    <h3>{idx + 1} - {block.Title} ({getPercentAc(assessment.results, block, idx + "")})</h3>
                    {
                        block.Blocks.map((block2, idx2) =>
                            <Box key={idx2}>
                                <h4>{(idx + 1) + "." + (idx2 + 1)} - {block2.Title} ({getPercentSo(assessment.results, block2, idx + "." + idx2)})</h4>
                                {
                                    block2.Metrics.map((metric, idx3) =>
                                        assessment.results.metrics[(idx) + "." + (idx2) + "." + (idx3)] && assessment.results.metrics[(idx) + "." + (idx2) + "." + (idx3)].selected ? <MetricResults key={idx3}
                                                       metricKey={(idx + 1) + "." + (idx2 + 1) + "." + (idx3 + 1)}
                                                       metric={metric}
                                                       results={assessment.results.metrics[(idx) + "." + (idx2) + "." + (idx3)]}/> : <div key={idx3}/>)
                                }
                            </Box>
                        )
                    }
                </Box>)
            }
        </Box> : <p>No results registered so far</p>
    }</Box>
}