import * as React from "react";
import {Box} from "@mui/material";
import FASTLogo from '../logos/fast_logo.png';
import Button from '@mui/material/Button';

export function HomePage() {
    return (
        <div>
            <Box sx={{display: "flex", flexDirection: 'row', width: "100%", marginTop: '10em'}}>
                <Box sx={{flex: 1 }}/>
                <Box sx={{flex: 3, textAlign: 'center'}}>
                    <img  src={FASTLogo} style={{width: "220px"}} alt="fast logo"/>
                    <h1>SINTEF FRAMEWORK ASSESSMENT TOOL</h1>
                    <Button sx={{marginTop: '2em'}} href={"/home"} variant="outlined">Go to the application</Button>
                </Box>
                <Box sx={{flex: 1}}/>
            </Box>
        </div>
    );
}