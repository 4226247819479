import * as React from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ChangeEvent, useEffect, useState} from "react";
import {
    Box,
    FormControl,
    InputLabel, MenuItem,
    Select, SelectChangeEvent
} from "@mui/material";
import {Framework, getAllFrameworks} from "../../data/framework";
import {Assessment, createAssessment, getAllAssessments} from "../../data/assessment";
import {NotAuthenticatedError} from "../../auth/auth_service";
import {useAuth} from "../../auth/auth_provider";
import {useNavigate} from "react-router-dom";

interface AddFrameworkDialogProps {
    open: boolean
    exitCallback: (data?: any) => void
}

function AddFrameworkDialog(props: AddFrameworkDialogProps) {
    const auth = useAuth();
    const nav = useNavigate();

    const [name, setName] = useState("");
    const [label, setLabel] = useState("");
    const [frameworkId, setFrameworkId] = useState("");
    const [frameworkList, setFrameworkList] = useState<Array<Framework>>([])
    const [baseAssessmentId, setBaseAssessmentId] = useState("");
    const [assessmentList, setAssessmentList] = useState<Array<Assessment>>([])
    const [loaded, setLoaded] = useState(false);

    const [nameErrorMessage, setNameErrorMessage] = useState("")
    const [labelErrorMessage, setLabelErrorMessage] = useState("")
    const [serverErrorMessage, setServerErrorMessage] = useState("")

    function resetErrors() {
        setNameErrorMessage("")
        setLabelErrorMessage("")
        setServerErrorMessage("")
    }

    function resetForm() {
        setName("")
        setLabel("")
        setFrameworkId("")
        setBaseAssessmentId("")
    }

    function onNameChanged(event: ChangeEvent<HTMLInputElement>) {
        setName(event.target.value)
        resetErrors()
    }

    function onLabelChanged(event: ChangeEvent<HTMLInputElement>) {
        setLabel(event.target.value)
        resetErrors()
    }

    function onFrameworkChanged(event: SelectChangeEvent<string>) {
        setFrameworkId(event.target.value)
        resetErrors()
    }

    function onBaseAssessmentChanged(event: SelectChangeEvent<string>) {
        setBaseAssessmentId(event.target.value)
        resetErrors()
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let formData = new FormData();
        formData.append("name", name);
        formData.append("label", label);
        formData.append("framework_id", frameworkId);
        formData.append("base_assessment_id", baseAssessmentId);
        createAssessment(formData).then(
            (newAssessment) => {
                resetErrors();
                resetForm();
                props.exitCallback(newAssessment)
            }
        ).catch(
            (e) => {
                console.error("[ERROR] Could not create a new assessment: ", e)
                setServerErrorMessage(e)
            }
        );
    }

    useEffect(() => {
        if (!loaded) {
            getAllFrameworks().then((frameworks) => {
                if (frameworks) {
                    setFrameworkList(frameworks)
                }
                getAllAssessments().then((previousAssessments) => {
                    if(previousAssessments) {
                        setAssessmentList(previousAssessments)
                    }
                })
            }).catch((e) => {
                if (e instanceof NotAuthenticatedError) {
                    nav("/login")
                }
                if (e.message.includes("401")) {
                    auth.signout(() => nav('/login'))
                } else {
                    console.error(e)
                }
            })
            setLoaded(true)
        }

    }, [frameworkList, loaded, auth, nav])

    return (
        <Dialog open={props.open} onClose={() => props.exitCallback(null)} aria-labelledby="form-dialog-new-assessment" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">New assessment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the assessment information below
                    </DialogContentText>
                    {
                        serverErrorMessage !== "" ?
                            <DialogContentText style={{color: "red"}}>
                                Error: {serverErrorMessage}
                            </DialogContentText> : null
                    }
                    <TextField
                        required
                        margin="dense"
                        id="Name"
                        label="Assessment name"
                        type="text"
                        fullWidth
                        placeholder="Name"
                        variant="outlined"
                        onChange={onNameChanged}
                        defaultValue={name}
                        error={nameErrorMessage !== ""}
                        helperText={nameErrorMessage !== "" ? nameErrorMessage : null}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="Label"
                        label="Label"
                        type="text"
                        fullWidth
                        placeholder="Label"
                        variant="outlined"
                        onChange={onLabelChanged}
                        defaultValue={label}
                        error={labelErrorMessage !== ""}
                        helperText={labelErrorMessage !== "" ? labelErrorMessage : null}
                    />
                    <Box sx={{ minWidth: 120, marginY: '0.5em' }}>
                        <FormControl fullWidth>
                            <InputLabel id="framework-id-selector">Framework</InputLabel>
                            <Select
                                labelId="framework-id-select-label"
                                id="framework-id-select"
                                value={frameworkId}
                                label="Framework"
                                onChange={onFrameworkChanged}
                            >
                                {frameworkList.map((framework, idx) => <MenuItem key={idx} value={framework.id}>{framework.Name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>
                    {
                        assessmentList.length > 0 ? <Box>
                            <DialogContentText>
                                To start from an existing assessment, select an existing assessment:
                            </DialogContentText>
                            <Box sx={{ minWidth: 120, marginY: '0.5em' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="assessment-id-selector">Base Assessment</InputLabel>
                                    <Select
                                        labelId="assessment-id-select-label"
                                        id="assessment-id-select"
                                        value={baseAssessmentId}
                                        label="Base Assessment"
                                        onChange={onBaseAssessmentChanged}
                                    >
                                        {assessmentList.map((assessment, idx) => <MenuItem key={idx} value={assessment.id}>{assessment.Name + " (" + assessment.Label +")"}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box> : <div/>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.exitCallback(null)} color="primary">
                        Cancel
                    </Button>
                    <Button type={"submit"} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddFrameworkDialog;