import {useLocation, useNavigate} from "react-router-dom";
import * as React from "react";
import {useAuth} from "../../auth/auth_provider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CssBaseline from '@mui/material/CssBaseline';
import {Alert, Button, Snackbar} from "@mui/material";
import {useEffect, useState} from "react";

export function LoginPage() {
    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    let [missingEmail, setMissingEmail] = useState(false)
    let [missingPassword, setMissingPassword] = useState(false)
    let [errorMessage, setErrorMessage] = useState("")

    // @ts-ignore
    let from = location.state?.from?.pathname || "/";

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        let email = formData.get("email") as string;
        let password = formData.get("password") as string;
        auth.signin(email, password, () => navigate(from, {replace: true})).catch((error: any) =>
            setErrorMessage(error.toString())
        );
    }

    useEffect(() => {
        if (auth.user) {
            navigate(from, {replace: true})
        }
    })

    function handleChange(event: React.ChangeEvent<HTMLInputElement>){
        if (event.target.name === "password"){
            if (event.target.value === "") {
                setMissingPassword(true)
            }
            else{
                setMissingPassword(false)
            }
        }
        else if (event.target.name === "email"){
            if (event.target.value === "") {
                setMissingEmail(true)
            }
            else{
                setMissingEmail(false)
            }
        }
        return
    }

    return (
        <div>
            <Container component="main" maxWidth="xs">
                { from !== "/" ? <p>You must log in to view the page at {from}</p> : <p/> }
                <CssBaseline/>
                <div>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            error={missingEmail}
                            helperText={missingEmail ? "Please provide your email address" : ""}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChange}
                        />
                        <TextField
                            error={missingPassword}
                            helperText={missingPassword ? "Please provide your password" : ""}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            Sign In
                        </Button>
                    </form>
                    <Snackbar open={errorMessage !== ""} onClose={() => setErrorMessage("")} autoHideDuration={5000} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
                        <Alert severity={"error"} onClose={() => setErrorMessage("")}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </div>
            </Container>
        </div>
    );

}