import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import BallotIcon from '@mui/icons-material/Ballot';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Assessment, getAllAssessments} from "../../../data/assessment";

export function AssessmentsCard(props: any) {
    const nav = useNavigate()
    let [assessmentCount, setAssessmentCount] = useState(0);

    useEffect(() => {
        getAllAssessments().then((assessments: Array<Assessment> | null) => {
            if (assessments) {
                setAssessmentCount(assessments.length)
            }
        })
    })

    return <Card
        sx={{height: '100%', cursor: 'pointer', boxShadow: "5px 5px 1px gray"}}
        onClick={() => nav("/home/assessments")}
        {...props}
        variant={"outlined"}
    >
        <CardContent>
            <Grid
                container
                spacing={3}
                sx={{justifyContent: 'space-between'}}
            >
                <Grid item>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="overline"
                    >
                        {"Assessment" + (assessmentCount > 1 ? "s" : "")}
                    </Typography>
                    <Typography
                        color="textPrimary"
                        variant="h4"
                    >
                        {assessmentCount}
                    </Typography>
                </Grid>
                <Grid item>
                    <Avatar
                        sx={{
                            backgroundColor: 'info.main',
                            height: 56,
                            width: 56
                        }}
                    >
                        <BallotIcon/>
                    </Avatar>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}