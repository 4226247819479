import {Box, CircularProgress, Container, Grid} from "@mui/material";
import * as React from "react";
import {useAuth} from "../../auth/auth_provider";
import {DashboardLayout} from "../../components/dashboard/dashboard-layout";
import {FrameworksCard} from "../../components/dashboard/cards/frameworks-card";
import {AssessmentsCard} from "../../components/dashboard/cards/assessments-cards";
import { UsersCard } from "../../components/dashboard/cards/users-card";

export function DashboardHomePage() {
    let auth = useAuth();

    function loading() {
        return <CircularProgress color="success"/>
    }

    function loggedInPage() {
        return (
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}
            >
                <Container maxWidth={false}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            lg={4}
                            sm={6}
                            xl={4}
                            xs={12}
                        >
                            <FrameworksCard />
                        </Grid>
                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={6}
                            xs={12}
                        >
                            <AssessmentsCard />
                        </Grid>
                        {
                            auth.user.roles.includes("ROLE_ADMIN") ? <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={6}
                                xs={12}
                            >
                                <UsersCard/>
                            </Grid> : <div/>
                        }
                    </Grid>
                </Container>
            </Box>
        )
    }

    return (
        <DashboardLayout pageTitle={"FAST"}>
            {
                Object.keys(auth.user).length === 0 ? loading() : loggedInPage()
            }
        </DashboardLayout>
    );
}