import {Box, ThemeProvider} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import * as React from "react";
import {theme} from '../../theme/theme';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    paddingLeft: 280
}));

export interface DashboardLayoutProps {
    pageTitle: string,
    children: React.ReactNode
}

export const DashboardLayout = (props: DashboardLayoutProps) => {
    let {pageTitle, children} = props;

    return (
        <ThemeProvider theme={theme}>
            <DashboardLayoutRoot>
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        width: '100%',
                        margin: '1.5em'
                    }}
                >
                    {children}
                </Box>
            </DashboardLayoutRoot>
            <DashboardNavbar pageTitle={pageTitle} />
            <DashboardSidebar/>
        </ThemeProvider>
    );
};