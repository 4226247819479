import {SURT_API_ADDRESS} from "../config";

const REFRESH_ENDPOINT = SURT_API_ADDRESS + "/api/v1/auth/refresh_token";

export function saveToken(token: string, expiresAt: string) {
    const authData = {token: token, expiresAt: expiresAt}
    try {
        localStorage.setItem('auth', JSON.stringify(authData))
    } catch (error) {
        console.log("could not persist to local storage: " + error)
    }
}

export function getToken(): string {
    try {
        const authDataJSON = localStorage.getItem('auth')

        if (authDataJSON === null) {
            return ""
        }

        const authData = JSON.parse(authDataJSON)

        const token = authData['token']
        const expiresAt = Date.parse(authData['expiresAt'])

        if (expiresAt - Date.now() < 0) {
            console.error("jwt.getToken token is expired")
            // The token is expired we return an empty string instead
            clearToken()
            return ""
        } else if (expiresAt - Date.now() < 5 * 60 * 1000) {
            // If the token expires in less than 5 minutes, we get a new one
            console.warn("jwt.getToken token expires in less than 5 minutes, consider refreshing it")
            return token
        }
        return token
    } catch(error) {
        console.error("Error while getting token: " + error)
        return ""
    }
}

export function clearToken() {
    console.debug("jwt.clearToken called")
    localStorage.removeItem('auth')
}

async function refreshToken(token: string): Promise<string> {
    console.log("jwt.refreshToken trying to refresh token")
    const request = new Request(REFRESH_ENDPOINT, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        credentials: "include",
    })

    return fetch(request)
        .then((apiResponse) => {
            if (!apiResponse.ok) {
                console.log("An error occurred while refreshing the token")
                return Promise.reject()
            }
            return apiResponse.text().then(response => JSON.parse(response))
        }).then((newTokenData) => {
            // Depending on the API, we might not get the token like that but in a cookie
            // ToDo: check that cookies only work?
            const token = newTokenData['token']
            saveToken(token, newTokenData['expire'])
            return token
        }).catch((error) => {
            console.log("Error while refreshing token: " + error)
            return Promise.reject()
        })
}