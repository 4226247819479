import GlobalState from "../interfaces/states/global-state";

export function selectMetric(assessment_id: string, id: string) {
    return function (state: GlobalState) {
        return state.assessmentResults[assessment_id]?.metrics[id]
    }
}

export function selectACFeedback(assessment_id: string, ac_id: string, feedback_id: number) {
    return function (state: GlobalState) {
        return state.assessmentResults[assessment_id]?.ac_feedbacks[ac_id]?.at(feedback_id)
    }
}

export function selectACFeedbacks(assessment_id: string, ac_id: string) {
    return function (state: GlobalState) {
        if (!Object.keys(state.assessmentResults).includes(assessment_id)) {
            return undefined
        }

        if (!Object.keys(state.assessmentResults[assessment_id]).includes("ac_feedbacks")) {
            return undefined
        }

        if (!state.assessmentResults[assessment_id]?.ac_feedbacks) {
            return undefined
        }

        return state.assessmentResults[assessment_id]?.ac_feedbacks[ac_id]
    }
}

export function selectSOFeedbacks(assessment_id: string, so_id: string) {
    return function (state: GlobalState) {
        if (!Object.keys(state.assessmentResults).includes(assessment_id)) {
            return undefined
        }

        if (!Object.keys(state.assessmentResults[assessment_id]).includes("so_feedbacks")) {
            return undefined
        }

        if (!state.assessmentResults[assessment_id]?.so_feedbacks) {
            return undefined
        }

        return state.assessmentResults[assessment_id]?.so_feedbacks[so_id]
    }
}