import * as React from "react";
import {DashboardLayout} from "../../../components/dashboard/dashboard-layout";
import {useEffect, useState} from "react";
import {useParams, useNavigate} from 'react-router-dom';
import {Box, Button, CircularProgress} from "@mui/material";
import {Assessment, getAssessment} from "../../../data/assessment";
import {Framework, getFramework} from "../../../data/framework";
import {AssessmentGeneratedForm} from "../../../components/framework/assessment-generated-form";
import {NotAuthenticatedError} from "../../../auth/auth_service";
import {useAuth} from "../../../auth/auth_provider";
import {loadAssessmentResult} from "../../../store/actions/assessment-results";
import {useDispatch} from 'react-redux'

export function DashboardAssessmentEditPage() {
    const nav = useNavigate();
    const auth = useAuth();

    let [currentAssessment, setCurrentAssessment] = useState<Assessment | null>(null)
    let [usedFramework, setUsedFramework] = useState<Framework | null>(null)
    let {id} = useParams();
    let dispatch = useDispatch();
    let assessment_id = id ? id : ""

    useEffect(() => {
        if (id !== undefined) {
            getAssessment(id).then((data: Assessment) => {
                setCurrentAssessment(data)
                if (data.results) {
                    dispatch(loadAssessmentResult(assessment_id, data.results))
                }
                getFramework(data.FrameworkId).then((framework: Framework) => {
                    setUsedFramework(framework)
                }).catch((e) => {
                    throw e
                })
            }).catch((e) => {
                if (e instanceof NotAuthenticatedError) {
                    nav("/login")
                }
                if (e.message.includes("401")) {
                    auth.signout(() => nav('/login'))
                } else {
                    console.error(e)
                }
            })
        }
    }, [id, nav, auth])

    return (
        <DashboardLayout pageTitle={"Assessment - Edit"}>
            {
                currentAssessment && usedFramework ? <div>
                    <AssessmentGeneratedForm assessment={currentAssessment} form={usedFramework.AssessmentForm}/>
                    <Box><Button variant="outlined" size="small" onClick={() => {
                        nav("/home/assessments/" + currentAssessment?.id)
                    }}>Back to view</Button></Box>
                </div> : <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "600px"}}>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <CircularProgress color="info"/>
                        <Box marginY={2}>Loading...</Box>
                    </Box>
                </Box>
            }
        </DashboardLayout>
    );
}