import {
    Box, Button,
    Card,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {SeverityPill} from "../generic/severity-pill";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {useNavigate} from "react-router-dom";
import {Assessment} from "../../data/assessment";
import {useEffect, useState} from "react";
import {NotAuthenticatedError} from "../../auth/auth_service";
import {Framework, getAllFrameworks} from "../../data/framework";
import {useAuth} from "../../auth/auth_provider";


export interface AssessmentsTableProps {
    assessments: Array<Assessment>,
    props?: any,
}

export function AssessmentsTable(props: AssessmentsTableProps) {
    const nav = useNavigate();
    const auth = useAuth();

    const [frameworks, setFrameworks] = useState<{[key: string]: Framework}>({})

    useEffect(() => {
        getAllFrameworks().then((data: Array<Framework>) => {
            if (data) {
                let frameworkMap: {[key: string]: Framework} = {}
                data.forEach((f) => frameworkMap[f.id] = f)
                setFrameworks(frameworkMap)
            }
        }).catch((e) => {
            if (e instanceof NotAuthenticatedError) {
                nav("/login")
            }
            if (e.message.includes("401")) {
                auth.signout(() => nav('/login'))
            } else {
                console.error(e)
            }
        })
    }, [nav, auth])

    return <Card {...props.props} variant={"outlined"}>
        <CardHeader title="Assessments"/>
        <PerfectScrollbar>
            <Box sx={{minWidth: 800}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell>
                                Label
                            </TableCell>
                            <TableCell>
                                Framework used
                            </TableCell>
                            <TableCell>
                                Created
                            </TableCell>
                            <TableCell>
                                Last updated
                            </TableCell>
                            <TableCell>
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.assessments.map((assessment: Assessment) => (
                            <TableRow
                                key={assessment.id}
                                onClick={() => nav("/home/assessments/" + assessment.id)}
                                sx={{cursor: "pointer"}}
                            >
                                <TableCell>
                                    {assessment.Name}
                                </TableCell>
                                <TableCell>
                                    {assessment.Label}
                                </TableCell>
                                <TableCell>
                                    {Object.keys(frameworks).includes(assessment.FrameworkId) ? frameworks[assessment.FrameworkId].Name : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {new Date(assessment.created_at).toDateString()}
                                </TableCell>
                                <TableCell>
                                    {new Date(assessment.updated_at).toDateString()}
                                </TableCell>
                                <TableCell>
                                    <SeverityPill
                                        color={(assessment.results ? 'success' : 'info')}
                                    >
                                        {(assessment.results ? 'On going' : 'Not Started')}
                                    </SeverityPill>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </PerfectScrollbar>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
            }}
        >
            <Button
                color="primary"
                endIcon={<ArrowRightIcon fontSize="small"/>}
                size="small"
                variant="text"
            >
                View all
            </Button>
        </Box>
    </Card>
}