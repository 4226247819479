import * as React from "react";
import {
    Routes,
    Route,
} from "react-router-dom";
import {LoginPage} from "./pages/auth/login-page";
import {DashboardHomePage} from "./pages/dashboard/homepage";
import {HomePage} from "./pages/homepage";
import {AuthProvider} from "./auth/auth_provider";
import {RequireAuth} from "./auth/require_auth";
import {DashboardUsersPage} from "./pages/dashboard/users/users-page";
import {DashboardFrameworksPage} from "./pages/dashboard/framework/frameworks-page";
import {DashboardFrameworkPage} from "./pages/dashboard/framework/single-framework-page";
import {DashboardFrameworkPreviewPage} from "./pages/dashboard/framework/framework-preview-page";
import {DashboardMyAssessmentsPage} from "./pages/dashboard/assessment/my-assessments-page";
import {DashboardAssessmentPage} from "./pages/dashboard/assessment/single-assessment-page";
import {DashboardAssessmentEditPage} from "./pages/dashboard/assessment/assessment-edit-page";
import {DashboardUserPage} from "./pages/dashboard/users/single-user-page";

export default function App() {
    return (
        <AuthProvider>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path={"/home"}>
                    <Route path="" element={<RequireAuth><DashboardHomePage/></RequireAuth>}/>
                    <Route path={"users"}>
                        <Route path="" element={<RequireAuth><DashboardUsersPage/></RequireAuth>}/>
                        <Route path=":id" element={<RequireAuth><DashboardUserPage/></RequireAuth>}/>
                    </Route>
                    <Route path="frameworks">
                        <Route path="" element={<RequireAuth><DashboardFrameworksPage/></RequireAuth>}/>
                        <Route path=":id" element={<RequireAuth><DashboardFrameworkPage/></RequireAuth>}/>
                        <Route path=":id/preview" element={<RequireAuth><DashboardFrameworkPreviewPage/></RequireAuth>}/>
                    </Route>
                    <Route path="assessments">
                        <Route path={""} element={<RequireAuth><DashboardMyAssessmentsPage/></RequireAuth>}/>
                        <Route path={":id"} element={<RequireAuth><DashboardAssessmentPage/></RequireAuth>}/>
                        <Route path={":id/edit"} element={<RequireAuth><DashboardAssessmentEditPage/></RequireAuth>}/>
                    </Route>
                </Route>
            </Routes>
        </AuthProvider>
    );
}



