import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {useEffect, useState} from "react";
import {Framework, getAllFrameworks} from "../../../data/framework";
import {useNavigate} from "react-router-dom";

export function FrameworksCard(props: any) {
    const nav = useNavigate()
    let [frameworkCount, setFrameworkCount] = useState(0);

    useEffect(() => {
        getAllFrameworks().then((frameworks: Array<Framework> | null) => {
            if (frameworks) {
                setFrameworkCount(frameworks.length)
            }
        })
    })

    return <Card
        sx={{height: '100%', cursor: 'pointer', boxShadow: "5px 5px 1px gray"}}
        onClick={() => nav("/home/frameworks")}
        {...props}
        variant={"outlined"}
    >
        <CardContent>
            <Grid
                container
                spacing={3}
                sx={{justifyContent: 'space-between'}}
            >
                <Grid item>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="overline"
                    >
                        {"Framework" + (frameworkCount > 1 ? "s" : "")}
                    </Typography>
                    <Typography
                        color="textPrimary"
                        variant="h4"
                    >
                        {frameworkCount}
                    </Typography>
                </Grid>
                <Grid item>
                    <Avatar
                        sx={{
                            backgroundColor: 'error.main',
                            height: 56,
                            width: 56
                        }}
                    >
                        <WidgetsIcon/>
                    </Avatar>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}