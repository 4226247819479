import styled from '@emotion/styled';
import {AppBar, Avatar, Box, Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip} from '@mui/material';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import Typography from "@mui/material/Typography";
import {useAuth} from "../../auth/auth_provider";
import React, {useState} from "react";
import {Logout, PersonAdd, Settings} from "@mui/icons-material";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
    // @ts-ignore
    backgroundColor: theme.palette.background.paper,
    // @ts-ignore
    boxShadow: theme.shadows[3]
}));

export interface DashboardNavbarProps {
    pageTitle: string
}

export const DashboardNavbar = (props: DashboardNavbarProps) => {
    let {pageTitle} = props;
    const auth = useAuth();

    // Menu
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutUser = () => {
        auth.signout(() => console.log("logged out"))
    }

    let username = (auth.user.first_name && auth.user.last_name) ? auth.user.first_name + " " + auth.user.last_name : "N/A" ;
    let role = auth.user.roles.includes("ROLE_ADMIN") ? "Administrator" : "Assessor"
    let avatar_color = auth.user.roles.includes("ROLE_ADMIN") ? "#0288d1" : "#29b6f6"

    return (
        <>
            <DashboardNavbarRoot
                sx={{
                    left: {
                        lg: 280
                    },
                    width: 'calc(100% - 280px)'
                }}>
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 64,
                        left: 0,
                        px: 2
                    }}
                >
                    <Typography variant="h1" component="h2" style={{color: "black"}}>
                        {pageTitle}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: "flex", flexDirection: "column", color: "black", marginX: 1}}>
                        <Box sx={{display: "flex", justifyContent: "flex-end"}}>{username}</Box>
                        <Box fontSize={"small"}>({role})</Box>
                    </Box>
                    <Tooltip title="Menu">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={{ width: 32, height: 32, bgcolor: avatar_color }}>{username[0]}</Avatar>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={logoutUser}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </DashboardNavbarRoot>
        </>
    );
};