import {
    Box, Button,
    Card,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import PerfectScrollbar from 'react-perfect-scrollbar';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {useNavigate} from "react-router-dom";
import {Framework} from "../../data/framework";


export interface FrameworksTableProps {
    frameworks: Array<Framework>,
    props?: any,
}

export function FrameworksTable(props: FrameworksTableProps) {
    const nav = useNavigate();

    return <Card {...props.props} variant={"outlined"}>
        <CardHeader title="Frameworks"/>
        <PerfectScrollbar>
            <Box sx={{minWidth: 800}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell>
                                Created
                            </TableCell>
                            <TableCell>
                                Last updated
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.frameworks.map((framework: Framework) => (
                            <TableRow
                                key={framework.id}
                                onClick={() => nav("/home/frameworks/" + framework.id)}
                                sx={{cursor: "pointer"}}
                            >
                                <TableCell>
                                    {framework.Name}
                                </TableCell>
                                <TableCell>
                                    {new Date(framework.created_at).toDateString()}
                                </TableCell>
                                <TableCell>
                                    {new Date(framework.updated_at).toDateString()}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </PerfectScrollbar>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
            }}
        >
            <Button
                color="primary"
                endIcon={<ArrowRightIcon fontSize="small"/>}
                size="small"
                variant="text"
            >
                View all
            </Button>
        </Box>
    </Card>
}