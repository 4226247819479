import React, {useState} from "react";
import {
    Alert,
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputLabel,
    LinearProgress, Link, MenuItem, Select,
    Tooltip
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import {Metric} from "../../data/framework";
import {FeedbackQuestionComponent} from "./assessment-form-feedback-question-component";
import {useDispatch, useSelector} from "react-redux";
import {selectMetric} from "../../store/selectors/assessment-results";
import {updateMetric} from "../../store/actions/assessment-results";
import {MetricResult} from "../../data/assessment-result";
import {useParams} from "react-router-dom";

export interface AssessmentMetricProps {
    idx: number,
    metric: Metric,
    parentIdx: string,
}

export function AssessmentMetric({idx, metric, parentIdx}: AssessmentMetricProps) {
    let {id} = useParams();
    let assessment_id = id ? id : ""
    const metricKey = parentIdx + "." + idx

    const [showFeedback, setShowFeedback] = useState(false);
    const metricData = useSelector(selectMetric(assessment_id, metricKey))
    const dispatch = useDispatch()

    function updateMetricFunc(metricKey: string, value: any) {
        dispatch(updateMetric(assessment_id, metricKey, value))
    }

    let metricValues: MetricResult = {
        "selected": true,
        "inputs": {},
        "outputs": {},
        "target_1": 0,
        "target_2": 0,
        "target_3": 0,
        "min_value": 0,
        "max_value": 0,
        "ref_1_value": 0,
        "ref_2_value": 0,
        "feedback": Array<string>().fill("", metric.Feedback ? metric.Feedback.length : 0),
    }

    if (typeof metricData !== "undefined") {
        metricValues = {
            ...metricValues,
            ...metricData
        }
    }

    if (!metricValues["selected"]) {
        return <Box/>
    }

    let evaluate = (formula: string, local_state: any) => {
        for (const key in local_state) {
            // @ts-ignore
            formula = formula.replace(key, local_state[key])
        }
        try {
            // eslint-disable-next-line no-new-func
            const res = Function("return Math.round((" + formula + ") * 100 ) / 100")()
            return !isNaN(res) ? res : 0
        } catch (e) {
            return 0;
        }
    }

    const normalise = (value: number) => {
        let shouldCompute = (metricValues["min_value"] || metricValues["min_value"] === 0) && metricValues["max_value"]
        if (metric.ReversedScale) {
            shouldCompute = (metricValues["max_value"] || metricValues["max_value"] === 0) && metricValues["min_value"]
        }

        if (shouldCompute) {
            const MIN = metricValues["min_value"]
            const MAX = metricValues["max_value"]

            let res = ((value - MIN) * 100) / (MAX - MIN)

            if (metric.ReversedScale) {
                const MIN = metricValues["max_value"]
                const MAX = metricValues["min_value"]
                res = ((MAX - value) * 100) / (MAX - MIN)
            }

            return Math.round(res * 100) / 100;
        }
        return 0;
    }

    const getColor = (value: number) => {
        // if there is no reference values, we return the blue color
        const ref_1 = metricValues["ref_1_value"]
        const ref_2 = metricValues["ref_2_value"]

        if (ref_1 === 0 && ref_2 === 0) {
            return 'info'
        }

        if (metric.ReversedScale) {
            if (value > normalise(ref_2)) {
                return 'success'
            } else if (value > normalise(ref_1)) {
                return 'warning'
            } else {
                return 'error'
            }
        }

        if (value <= normalise(ref_1)) {
            return 'error'
        } else if (value <= normalise(ref_2)) {
            return 'warning'
        } else {
            return 'success'
        }
    }

    const getPerformanceValue = () => {
        if (Object.keys(metricValues["outputs"]).length > 0) {
            return metricValues["outputs"][Object.keys(metricValues["outputs"])[0]]
        } else {
            return 0;
        }
    }

    function updateMetricValues(fieldName: string, value: string | number) {
        let new_inputs = typeof value === "string" ? {
            ...metricValues["inputs"],
            [fieldName]: Number(value),
        } : {
            ...metricValues["inputs"],
            [fieldName]: value,
        }

        // we recalculate all the outputs based on the new local state before updating the state
        let outputs: { [id: string]: any } = {}
        metric.CalculatedFields.forEach((oField, idx) => {
            outputs[oField.Name] = evaluate(oField.Formula, new_inputs)
        })

        updateMetricFunc(metricKey, {
            ...metricValues,
            "inputs": new_inputs,
            "outputs": outputs,
        })
    }

    function updateMinValue(value: string) {
        updateMetricFunc(metricKey, {
            ...metricValues,
            "min_value": Number(value),
        })
    }

    function updateMaxValue(value: string) {
        updateMetricFunc(metricKey, {
            ...metricValues,
            "max_value": Number(value),
        })
    }

    function updateRefValue(idx: number, value: string) {
        updateMetricFunc(metricKey, {
            ...metricValues,
            ["ref_" + idx + "_value"]: Number(value),
        })
    }

    function updateTargetValue(targetIdx: number, value: string) {
        updateMetricFunc(metricKey, {
            ...metricValues,
            ["target_" + targetIdx]: Number(value),
        })
    }

    function updateFeedbackValue(idx: number, value: any) {
        let feedback = metricValues["feedback"]
        feedback[idx] = value
        updateMetricFunc(metricKey, {
            ...metricValues,
            "feedback": feedback,
        })
    }

    return <Box>
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Box><h4>Metric {idx + 1} - {metric.Name}</h4></Box>
            {
                metric.Link ?
                    <Box><Tooltip title="More information"><IconButton color="secondary" aria-label="more info"
                                                                       component="span"
                                                                       onClick={() => window.open(metric.Link, '_blank')}>
                        <InfoIcon/>
                    </IconButton></Tooltip></Box> : <div/>
            }
        </Box>
        <p>{metric.Description}</p>
        <h5>Inputs</h5>
        <Box sx={{display: "grid", gridTemplateColumns: 'repeat(5, 1fr)'}}>
            {
                metric.InputFields.map((f, fIdx) =>
                    f.Type === "number" ? <FormControl key={fIdx} sx={{m: 2}}>
                        <InputLabel htmlFor={f.Name}>{f.Name}</InputLabel>
                        <Input id={f.Name} aria-describedby={f.Name} type={f.Type}
                               inputProps={{min: f.MinValue, max: f.MaxValue !== 0 ? f.MaxValue : null}}
                               value={metricValues["inputs"][f.Name] || metricValues["inputs"][f.Name] === 0 ? metricValues["inputs"][f.Name] : ""}
                               onChange={(event) => {
                                   updateMetricValues(f.Name, event.target.value)
                               }}/>
                        <FormHelperText
                            id={f.Name + "-help"}>{f.Description} {f.Unit !== "" ? "(unit: " + f.Unit + ")" : ""}</FormHelperText>
                    </FormControl> : <FormControl key={fIdx} sx={{m: 2}}>
                        <InputLabel htmlFor={f.Name}>{f.Name}</InputLabel>
                        <Select
                            id={f.Name} aria-describedby={f.Name} type={f.Type}
                            value={metricValues["inputs"][f.Name] || metricValues["inputs"][f.Name] === 0 ? metricValues["inputs"][f.Name] : ""}
                            label={f.Name}
                            onChange={(event) => {
                                updateMetricValues(f.Name, event.target.value)
                            }}
                        >
                            {Object.keys(f.Options).map((choiceKey, choiceIdx) => <MenuItem key={choiceIdx}
                                                                                            value={f.Options[choiceKey]}>{choiceKey}</MenuItem>)}
                        </Select>
                    </FormControl>
                )
            }
        </Box>
        <h5>Calculated</h5>
        <Box sx={{display: "flex", m: 2}}>
            {
                metric.CalculatedFields.map((f, fIdx) =>
                    <FormControl key={fIdx} sx={{flex: 1, m: 2}}>
                        <InputLabel htmlFor={f.Name}>{f.Name}</InputLabel>
                        <Input disabled id={f.Name} aria-describedby={f.Name}
                               value={metricValues["outputs"][f.Name] ? metricValues["outputs"][f.Name] : ""}/>
                        <FormHelperText
                            id={f.Name + "-help"}>{f.Description + " (unit: " + metric.Unit + ")"}</FormHelperText>
                    </FormControl>
                )
            }
        </Box>
        {
            <div>
                <h5>Performance assessment</h5>
                <Box sx={{display: "flex", flexDirection: "row"}}>
                    <Box m={2}>
                        <FormControl>
                            <InputLabel htmlFor="ref_1_value">Ref. value for acceptable performance</InputLabel>
                            <Input id={"ref-value-1-input"} aria-describedby={"input reference value 1"}
                                   type={"number"}
                                   value={metricValues["ref_1_value"]}
                                   onChange={(event) => updateRefValue(1, event.target.value)}
                                   fullWidth />
                            <FormHelperText
                                id={"ref-1-value-help"}>{normalise(metricValues["ref_1_value"]) + "%"}</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box m={2}>
                        <FormControl>
                            <InputLabel htmlFor="ref_2_value">Ref. value for good performance</InputLabel>
                            <Input id={"ref-value-2-input"} aria-describedby={"input reference value 2"}
                                   type={"number"}
                                   value={metricValues["ref_2_value"]}
                                   onChange={(event) => updateRefValue(2, event.target.value)}
                                   fullWidth />
                            <FormHelperText
                                id={"ref-2-value-help"}>{normalise(metricValues["ref_2_value"]) + "%"}</FormHelperText>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{display: "flex", flexDirection: "row"}}>
                    <Box m={2}>
                        <FormControl>
                            <InputLabel htmlFor="target_1">Target 1</InputLabel>
                            <Input id={"target-1-input"} aria-describedby={"input target 1"}
                                   type={"number"}
                                   value={metricValues["target_1"]}
                                   onChange={(event) => updateTargetValue(1, event.target.value)}/>
                            <FormHelperText
                                id={"target-1-help"}>{normalise(metricValues["target_1"]) + "%"}</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box m={2}>
                        <FormControl>
                            <InputLabel htmlFor="target_2">Target 2</InputLabel>
                            <Input id={"target-2-input"} aria-describedby={"input target 2"}
                                   type={"number"}
                                   value={metricValues["target_2"]}
                                   onChange={(event) => updateTargetValue(2, event.target.value)}/>
                            <FormHelperText
                                id={"target-2-help"}>{normalise(metricValues["target_2"]) + "%"}</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box m={2}>
                        <FormControl>
                            <InputLabel htmlFor="target_3">Target 3</InputLabel>
                            <Input id={"target-3-input"} aria-describedby={"input target 3"}
                                   type={"number"}
                                   value={metricValues["target_3"]}
                                   onChange={(event) => updateTargetValue(3, event.target.value)}/>
                            <FormHelperText
                                id={"target-3-help"}>{normalise(metricValues["target_3"]) + "%"}</FormHelperText>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{display: "flex", flexDirection: "row"}}>
                    <Box m={2}>
                        <FormControl>
                            <InputLabel htmlFor="min_value">Most unfavorable value</InputLabel>
                            <Input id={"min-value-input"} aria-describedby={"input min value"}
                                   type={"number"}
                                   value={metricValues["min_value"]}
                                   onChange={(event) => updateMinValue(event.target.value)}/>
                        </FormControl>
                    </Box>
                    <Box m={2}>
                        <FormControl>
                            <InputLabel htmlFor="max_value">Most ambitious objective</InputLabel>
                            <Input id={"max-value-input"} aria-describedby={"input max value"}
                                   type={"number"}
                                   value={metricValues["max_value"]}
                                   onChange={(event) => updateMaxValue(event.target.value)}/>
                        </FormControl>
                    </Box>
                </Box>
                {
                    metric.ReversedScale ? <p><em>Note: the scale is reversed for this metric</em></p> : <div/>
                }
                {

                    Object.keys(metricValues["outputs"]).length > 0 ?
                        <div>{
                            normalise(getPerformanceValue()) > 100 || normalise(getPerformanceValue()) < 0 ?
                                <Alert severity="warning">The output is odd, please ensure all values are
                                    correct.</Alert> : <div/>
                        }
                            <Box m={1}>{normalise(getPerformanceValue()) + "%"}</Box><LinearProgress
                                variant="determinate" color={getColor(normalise(getPerformanceValue()))}
                                value={normalise(getPerformanceValue())}/></div>
                        : <div/>

                }
            </div>
        }
        {
            metric.Feedback && metric.Feedback.length > 0 ? <div>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Box><h5>Metric Feedback</h5></Box>
                    <Box marginX={1}><Link sx={{cursor: "pointer"}}
                                           onClick={() => setShowFeedback(!showFeedback)}>{showFeedback ? "hide" : "see"}</Link></Box>
                </Box>
                {
                    showFeedback ? <Box sx={{display: "flex", flexDirection: 'column'}}>
                        {
                            metric.Feedback.map((feedback_question, idx) =>
                                <FeedbackQuestionComponent key={idx} idx={idx} fbck_question={feedback_question}
                                                           value={metricValues ? (idx < metricValues["feedback"].length ? metricValues["feedback"][idx] : "") : null}
                                                           updateFunc={updateFeedbackValue}/>
                            )
                        }
                    </Box> : <Box/>
                }
            </div> : <div/>
        }
    </Box>
}