import {applyMiddleware, Middleware} from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

const REDUX_LOGGING = false;

const middleware: Middleware[] = [thunk];

if (REDUX_LOGGING) {
    middleware.push(logger);
}

export default applyMiddleware(...middleware);