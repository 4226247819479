import * as React from "react";
import {DashboardLayout} from "../../../components/dashboard/dashboard-layout";
import {useEffect, useState} from "react";
import {Box, Button} from "@mui/material";
import {Assessment, getAllAssessments} from "../../../data/assessment";
import AddAssessmentDialog from "../../../components/dialogs/add-assessment-dialog";
import {AssessmentsTable} from "../../../components/assessment/assessments-table";
import {NotAuthenticatedError} from "../../../auth/auth_service";
import {useAuth} from "../../../auth/auth_provider";
import {useNavigate} from "react-router-dom";

export function DashboardMyAssessmentsPage() {
    const auth = useAuth();
    const nav = useNavigate();

    let [assessments, setAssessments] = useState([]);
    let [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        getAllAssessments().then((data) => {
            if (data) { setAssessments(data); }
        }).catch((e) => {
            if (e instanceof NotAuthenticatedError) {
                nav("/login")
            }
            if (e.message.includes("401")) {
                auth.signout(() => nav('/login'))
            } else {
                console.error(e)
            }
        })
    }, [nav, auth])

    return (
        <DashboardLayout pageTitle={"Assessments"}>
            <p>Here you can find the assessments you have performed or that are still on going.</p>
            <AssessmentsTable assessments={assessments}/>
            <Box marginTop={2} ><Button variant="outlined" size="small" onClick={() => {
                setDialogOpen(true)
            }}>New assessment</Button></Box>
            <AddAssessmentDialog open={dialogOpen} exitCallback={(newAssessment: Assessment | undefined) => {
                if (newAssessment && typeof newAssessment !== "undefined") {
                    // @ts-ignore
                    setAssessments([...assessments, newAssessment])
                }
                setDialogOpen(false)
            }}/>
        </DashboardLayout>
    );
}