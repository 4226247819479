import * as React from "react";
import {DashboardLayout} from "../../../components/dashboard/dashboard-layout";
import {Box, Button} from "@mui/material";
import {UsersTable} from "../../../components/user/users-table";
import {getAllUsers, User} from "../../../data/user";
import {useEffect, useState} from "react";
import AddUserDialog from "../../../components/dialogs/add-user-dialog";
import {NotAuthenticatedError} from "../../../auth/auth_service";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../auth/auth_provider";

export function DashboardUsersPage() {
    let [users, setUsers] = useState<Array<User>>([]);
    let [openDialog, setOpenDialog] = useState(false);
    let nav = useNavigate();
    let auth = useAuth();

    useEffect(() => {
        getAllUsers().then((data) => {
            if (data) { setUsers(data); }
        }).catch((e: Error) => {
            if (e instanceof NotAuthenticatedError) {
                nav("/login")
            }
            if (e.message.includes("401")) {
                auth.signout(() => nav('/login'))
            } else {
                console.error(e)
            }
        })
    }, [nav, auth])

    return (
        <DashboardLayout pageTitle={"Users"}>
            <UsersTable users={users}/>
            <Box marginTop={2} ><Button variant="outlined" size="small" onClick={() => {
                setOpenDialog(true)
            }}>Create user</Button></Box>
            <AddUserDialog open={openDialog} exitCallback={(newUser: User | undefined) => {
                if (newUser && typeof newUser !== "undefined") {
                    // @ts-ignore
                    setUsers([...users, newUser])
                }
                setOpenDialog(false)
            }}/>
        </DashboardLayout>
    );
}