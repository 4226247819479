import {Box} from "@mui/material";
import React from "react";
import {FeedbackQuestion} from "../../data/framework";
import {FeedbackQuestionComponent} from "./assessment-form-feedback-question-component";


export function AFFeedbackComponent({feedback_questions, type, updateFunc, state}: { feedback_questions: Array<FeedbackQuestion>, type: "initial" | "secondary", updateFunc: (idx : number, value: any) => void, state: any }) {
    if (!feedback_questions) {
        return <Box>
            <h1>No {type} feedback</h1>
        </Box>
    }

    return <Box>
        <h1>{type === "initial" ? "Feedback on the overall BWS framework - A" : "Feedback on the overall BWS framework - B"}</h1>
        {
            feedback_questions.map((fq, idx) => <FeedbackQuestionComponent key={idx} idx={idx} fbck_question={fq} value={state[idx]} updateFunc={updateFunc}/>)
        }
    </Box>
}