import {clearToken, getToken, saveToken} from './jwt';
import {SURT_API_ADDRESS} from "../config";
import * as jose from 'jose'
import {getAuthHeader} from "./auth_provider";

const LOGIN_ENDPOINT = SURT_API_ADDRESS + "/api/v1/auth/login";
const LOGOUT_ENDPOINT = SURT_API_ADDRESS + "/api/v1/auth/logout";

interface LoginProps {
    email: string
    password: string
}

export class NotAuthenticatedError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "NotAuthenticatedError";
    }
}

export const AuthService = {
    login: ({ email, password }: LoginProps) => {
        const request = new Request(LOGIN_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, expire }) => {
                console.log(expire)
                return saveToken(token, expire);
            })
    },
    logout: () => {
        const request = new Request(LOGOUT_ENDPOINT, {
            method: 'GET',
            headers: new Headers({
                'Authorization': getAuthHeader().Authorization,
                'Content-Type': 'application/json'
            }),
            credentials: 'include',
        });
        clearToken();
        return fetch(request);
    },
    getCurrentUser() {
        let token = getToken()
        if (token !== "") {
            const claims = jose.decodeJwt(token)
            return {
                email: claims["email"],
                first_name: claims["first_name"],
                last_name: claims["last_name"],
                roles: claims["roles"]
            }
        } else {
            return null;
        }
    }
};

export interface AuthContextType {
    user: any;
    signin: (email: string, password: string, callback: VoidFunction) => any;
    signout: (callback: VoidFunction) => void;
}
