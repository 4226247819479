import {combineReducers, createStore} from 'redux'
import middleware from './middleware';
import * as reducers from "./reducers";

const combinedReducers = combineReducers({
        ...reducers,
    }
)

const store = createStore(combinedReducers, middleware);

export default store;