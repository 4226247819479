import * as React from "react";
import {DashboardLayout} from "../../../components/dashboard/dashboard-layout";
import {useEffect, useState} from "react";
import {Framework, getFramework} from "../../../data/framework";
import { useParams, useNavigate } from 'react-router-dom';
import {Box, Button, CircularProgress} from "@mui/material";
import {NotAuthenticatedError} from "../../../auth/auth_service";
import {useAuth} from "../../../auth/auth_provider";

export function DashboardFrameworkPage() {
    const nav = useNavigate();
    const auth = useAuth();
    let [currentFramework, setCurrentFramework] = useState<Framework | null>(null)
    let { id } = useParams();

    useEffect(() => {
        if (id !== undefined) {
            getFramework(id).then((data: Framework) => {
                setCurrentFramework(data)
            }).catch((e) => {
                if (e instanceof NotAuthenticatedError) {
                    nav("/login")
                }
                if (e.message.includes("401")) {
                    auth.signout(() => nav('/login'))
                } else {
                    console.error(e)
                }
            })
        }
    }, [id, nav, auth])
    const exportFramework = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(currentFramework?.AssessmentForm)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "framework.json";

        link.click();
    };

    return (
        <div>
            <DashboardLayout pageTitle={"Framework"}>
                {
                    currentFramework ? <div>
                        <h1>{currentFramework.Name}</h1>
                        <Box><Button variant="outlined" size="small" onClick={() => {
                            nav("/home/frameworks/" + currentFramework?.id + "/preview")
                        }}>Preview form</Button></Box>
                        <br/>
                        <Box><Button variant="outlined" size="small" onClick={exportFramework}>Download Framework (as JSON)</Button></Box>
                    </div> : <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "600px" }}>
                        <Box sx={{display:"flex", flexDirection: "column"}}>
                            <CircularProgress color="info" />
                            <Box marginY={2}>Loading...</Box>
                        </Box>
                    </Box>
                }
            </DashboardLayout>
        </div>
    );
}