import {
    Box, Button,
    Card,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {SeverityPill} from "../generic/severity-pill";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {useNavigate} from "react-router-dom";
import {User} from "../../data/user";


export interface UsersTableProps {
    users: Array<User>,
    props?: any,
}

export function UsersTable(props: UsersTableProps) {
    const nav = useNavigate();

    return <Card {...props.props} variant={"outlined"}>
        <CardHeader title="Users"/>
        <PerfectScrollbar>
            <Box sx={{minWidth: 800}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell>
                                Email
                            </TableCell>
                            <TableCell>
                                Created
                            </TableCell>
                            <TableCell>
                                Last updated
                            </TableCell>
                            <TableCell>
                                Role
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.users.map((user: User) => (
                            <TableRow
                                key={user.id}
                                onClick={() => nav("/home/users/" + user.id)}
                                sx={{cursor: "pointer"}}
                            >
                                <TableCell>
                                    {user.first_name + " " + user.last_name}
                                </TableCell>
                                <TableCell>
                                    {user.email}
                                </TableCell>
                                <TableCell>
                                    {new Date(user.created_at).toDateString()}
                                </TableCell>
                                <TableCell>
                                    {new Date(user.updated_at).toDateString()}
                                </TableCell>
                                <TableCell>
                                    <SeverityPill
                                        color={(user.roles.filter((v) => v.name === "ROLE_ADMIN").length > 0 ? 'success' : 'info')}
                                    >
                                        {(user.roles.filter((v) => v.name === "ROLE_ADMIN").length > 0 ? 'Admin' : 'Assessor')}
                                    </SeverityPill>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </PerfectScrollbar>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
            }}
        >
            <Button
                color="primary"
                endIcon={<ArrowRightIcon fontSize="small"/>}
                size="small"
                variant="text"
            >
                View all
            </Button>
        </Box>
    </Card>
}