import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from "@mui/material";
import {Framework, getFramework} from "../../../data/framework";
import {NotAuthenticatedError} from "../../../auth/auth_service";
import {useAuth} from "../../../auth/auth_provider";

export function DashboardFrameworkPreviewPage() {
    const nav = useNavigate();
    const auth = useAuth();
    let [currentFramework, setCurrentFramework] = useState<Framework | null>(null)
    let {id} = useParams();

    useEffect(() => {
        if (id !== undefined) {
            getFramework(id).then((data: Framework) => {
                setCurrentFramework(data)
            }).catch((e) => {
                if (e instanceof NotAuthenticatedError) {
                    nav("/login")
                }
                if (e.message.includes("401")) {
                    auth.signout(() => nav('/login'))
                } else {
                    console.error(e)
                }
            })
        }
    }, [id, nav, auth])

    return (
        <div>
            <h1>Preview of {currentFramework?.Name}</h1>
            <Button variant="outlined" size="small" onClick={() => {
                nav("/home/frameworks/" + id)
            }}>Go back</Button>
            {/*<AssessmentGeneratedForm form={currentFramework?.AssessmentForm}/>*/}
        </div>
    );
}