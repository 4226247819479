import {AssessmentResult, MetricResult} from "../../data/assessment-result";
import {AssessmentResultsAction} from "../actions/assessment-results";
import {
    DeselectAllMetricsAction,
    LoadAssessmentResultAction, SelectAllMetricsAction,
    UpdateACFeedbackAction,
    UpdateMetricAction, UpdateSOFeedbackAction
} from "../interfaces/actions/assessment-results";
import {extractMetricsKeys} from "../../data/framework";

const initialState: { [key: string]: AssessmentResult } = {}

export default function assessmentResults(state = initialState, action: UpdateMetricAction & SelectAllMetricsAction & DeselectAllMetricsAction & LoadAssessmentResultAction & UpdateACFeedbackAction & UpdateSOFeedbackAction) {
    switch (action.type) {
        case AssessmentResultsAction.UpdateMetric:
            if (Object.keys(state).includes(action.assessment_id)) {
                return {
                    ...state,
                    [action.assessment_id]: {
                        ...state[action.assessment_id],
                        metrics: {
                            ...state[action.assessment_id].metrics,
                            [action.metric_id]: action.metric_value
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    [action.assessment_id]: {
                        metrics: {
                            [action.metric_id]: action.metric_value
                        },
                        ac_feedbacks: {},
                        so_feedbacks: {},
                    }
                }
            }
        case AssessmentResultsAction.SelectAllMetrics:
            let metricsKeys = extractMetricsKeys(action.form)
            if (Object.keys(state).includes(action.assessment_id)) {
                let newMetrics = {...state[action.assessment_id].metrics}
                metricsKeys.forEach((metricKey) => {
                    // we update the existing metric
                    if (Object.keys(newMetrics).includes(metricKey)) {
                        newMetrics[metricKey].selected = true
                    } else { // we create the metric
                        newMetrics[metricKey] = {
                            "selected": true,
                            "inputs": {},
                            "outputs": {},
                            "target_1": 0,
                            "target_2": 0,
                            "target_3": 0,
                            "min_value": 0,
                            "max_value": 0,
                            "ref_1_value": 0,
                            "ref_2_value": 0,
                            "feedback": Array<string>(),
                        }
                    }
                })
                return {
                    ...state,
                    [action.assessment_id]: {
                        ...state[action.assessment_id],
                        metrics: newMetrics
                    }
                }
            } else {
                // we create all the metrics from scratch before
                let newMetrics: { [key: string]: MetricResult } = {}
                metricsKeys.forEach((metricKey) => {
                    newMetrics[metricKey] = {
                        "selected": true,
                        "inputs": {},
                        "outputs": {},
                        "target_1": 0,
                        "target_2": 0,
                        "target_3": 0,
                        "min_value": 0,
                        "max_value": 0,
                        "ref_1_value": 0,
                        "ref_2_value": 0,
                        "feedback": Array<string>(),
                    }
                })
                return {
                    ...state,
                    [action.assessment_id]: {
                        metrics: newMetrics,
                        ac_feedbacks: {},
                        so_feedbacks: {},
                    }
                }
            }
        case AssessmentResultsAction.DeselectAllMetrics:
            let metricsKeys2 = extractMetricsKeys(action.form)
            if (Object.keys(state).includes(action.assessment_id)) {
                let newMetrics = {...state[action.assessment_id].metrics}
                metricsKeys2.forEach((metricKey) => {
                    // we update the existing metric
                    if (Object.keys(newMetrics).includes(metricKey)) {
                        newMetrics[metricKey].selected = false
                    } else { // we create the metric
                        newMetrics[metricKey] = {
                            "selected": false,
                            "inputs": {},
                            "outputs": {},
                            "target_1": 0,
                            "target_2": 0,
                            "target_3": 0,
                            "min_value": 0,
                            "max_value": 0,
                            "ref_1_value": 0,
                            "ref_2_value": 0,
                            "feedback": Array<string>(),
                        }
                    }
                })
                return {
                    ...state,
                    [action.assessment_id]: {
                        ...state[action.assessment_id],
                        metrics: newMetrics
                    }
                }
            } else {
                // we create all the metrics from scratch before
                let newMetrics: { [key: string]: MetricResult } = {}
                metricsKeys2.forEach((metricKey) => {
                    newMetrics[metricKey] = {
                        "selected": false,
                        "inputs": {},
                        "outputs": {},
                        "target_1": 0,
                        "target_2": 0,
                        "target_3": 0,
                        "min_value": 0,
                        "max_value": 0,
                        "ref_1_value": 0,
                        "ref_2_value": 0,
                        "feedback": Array<string>(),
                    }
                })
                return {
                    ...state,
                    [action.assessment_id]: {
                        metrics: newMetrics,
                        ac_feedbacks: {},
                        so_feedbacks: {},
                    }
                }
            }
        case AssessmentResultsAction.UpdateACFeedback:
            if (Object.keys(state).includes(action.assessment_id)) {
                let oldFeedbacks = state[action.assessment_id].ac_feedbacks ? state[action.assessment_id].ac_feedbacks[action.ac_id] : []
                if (!oldFeedbacks) {
                    oldFeedbacks = []
                }
                let newFeedback = [...oldFeedbacks]
                newFeedback[action.feedback_id] = action.feedback_value
                return {
                    ...state,
                    [action.assessment_id]: {
                        ...state[action.assessment_id],
                        ac_feedbacks: {
                            ...state[action.assessment_id].ac_feedbacks,
                            [action.ac_id]: newFeedback
                        }
                    }
                }
            } else {
                let newFeedback = []
                newFeedback[action.feedback_id] = action.feedback_value
                return {
                    ...state,
                    [action.assessment_id]: {
                        metrics: {},
                        so_feedbacks: {},
                        ac_feedbacks: {
                            [action.ac_id]: newFeedback
                        }
                    }
                }
            }
        case AssessmentResultsAction.UpdateSOFeedback:
            if (Object.keys(state).includes(action.assessment_id)) {
                let oldFeedbacks = state[action.assessment_id].so_feedbacks ? state[action.assessment_id].so_feedbacks[action.so_id] : []
                if (!oldFeedbacks) {
                    oldFeedbacks = []
                }
                let newFeedback = [...oldFeedbacks]
                newFeedback[action.feedback_id] = action.feedback_value
                return {
                    ...state,
                    [action.assessment_id]: {
                        ...state[action.assessment_id],
                        so_feedbacks: {
                            ...state[action.assessment_id].so_feedbacks,
                            [action.so_id]: newFeedback
                        }
                    }
                }
            } else {
                let newFeedback = []
                newFeedback[action.feedback_id] = action.feedback_value
                return {
                    ...state,
                    [action.assessment_id]: {
                        metrics: {},
                        ac_feedbacks: {},
                        so_feedbacks: {
                            [action.so_id]: newFeedback
                        }
                    }
                }
            }
        case AssessmentResultsAction.LoadAssessmentResult:
            return {
                ...state,
                [action.assessment_id]: action.value,
            }
        default:
            return state
    }
}